import { useEffect } from 'react';
import { CloseModal, ProjectEmpty } from '../../../../assets/svgs';
import { queryStrings } from '../../../../constants';
import { Loader } from '../../../../ui';
import { Container } from './style';
import { useSearchParams } from 'react-router-dom';
import { getDateTime, getPreviousSearchParams } from '../../../../utilities';
import { useGetMatch } from '../../../../hooks';

const MatchDetails = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const matchId = searchParams.get(queryStrings.match_id);

	const { data: match, isLoading, isError } = useGetMatch({ match_id: matchId || '' });

	console.log({ match });

	const handleClose = () => {
		let previousParams = getPreviousSearchParams(searchParams);

		delete previousParams[queryStrings.match_id];

		setSearchParams(previousParams, { replace: true });
	};

	useEffect(() => {
		if (matchId) return;

		let previousParams = getPreviousSearchParams(searchParams);

		delete previousParams[queryStrings.match_id];

		setSearchParams(previousParams, { replace: true });
	}, [matchId, searchParams, setSearchParams]);

	return (
		<>
			<Container>
				{isLoading ? (
					<center>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<div>
							<Loader />
						</div>
					</center>
				) : isError ? (
					<center>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<div>
							<div>
								<ProjectEmpty />
								<p>Unable to fetch details at the moment</p>
							</div>
						</div>
					</center>
				) : (
					<div>
						<div className="header">
							<CloseModal tabIndex={0} onClick={handleClose} />
							<h2>Internship Match</h2>
							<hr />
						</div>

						<section>
							<p>{match?.description}</p>
						</section>

						<section>
							<h4>Project Details</h4>

							<div>
								<article className="flexed">
									<div>
										<b>Project Name</b>
										<p>{match?.project_details?.name}</p>
									</div>
									<div>
										<b>Sector</b>
										<p>{match?.project_details?.sector}</p>
									</div>

									<div>
										<b>Total Interns</b>
										<p>{match?.project_details?.total_interns}</p>
									</div>
								</article>

								<article className="flexed">
									<div>
										<b>Project Timeline</b>
										<p>{match?.project_details?.project_timeline}</p>
									</div>

									<div>
										<b>Start Date</b>
										<p>{getDateTime(match?.project_details?.start_date, 'mm dd yyyy')}</p>
									</div>

									<div>
										<b>End Date</b>
										<p>{getDateTime(match?.project_details?.end_date, 'mm dd yyyy')}</p>
									</div>
								</article>
							</div>
						</section>
						<section>
							<h4>Intern Details</h4>

							<article className="flexed">
								<div>
									<b>Name</b>
									<p>{match?.intern_details?.name}</p>
								</div>

								<div>
									<b>Email</b>
									<p>{match?.intern_details?.email}</p>
								</div>

								<div>
									<b>Phone</b>
									<p>{match?.intern_details?.phone_number}</p>
								</div>
							</article>
							<article className="flexed">
								<div>
									<b>Highest Edu Level</b>
									<p>{match?.intern_details?.highest_edu_level}</p>
								</div>

								<div>
									<b>School</b>
									<p>{match?.intern_details?.school}</p>
								</div>

								<div>
									<b>Area of study</b>
									<p>{match?.intern_details?.area_of_study}</p>
								</div>
							</article>

							<article className="flexed">
								<div>
									<b>Internship Duration</b>
									<p>{match?.intern_details?.internship_timeline}</p>
								</div>
								<div>
									<b>Start Date</b>
									<p>{match?.intern_details?.start_date}</p>
								</div>
								<div>
									<b>End Date</b>
									<p>{match?.intern_details?.end_date}</p>
								</div>
							</article>
						</section>
					</div>
				)}
			</Container>
		</>
	);
};
export default MatchDetails;
