import { Button, Loader } from '../../../../ui';
import { CloseModal, ProjectEmpty } from '../../../../assets/svgs';
import { Container } from './styles';
import { useGetBlog } from '../../../../hooks';
import { Link } from 'react-router-dom';
import { Approutes } from '../../../../constants';

type ViewBlogModalProp = {
	postId: string | number;
	handleClose: () => void;
	handleEdit: (id: string | number) => void;
};

const ViewBlogModal = ({ postId, handleClose, handleEdit }: ViewBlogModalProp) => {
	const { data: blog, isLoading: isFetching, isError } = useGetBlog({ id: postId });

	return (
		<Container>
			{isFetching ? (
				<div>
					<CloseModal className="close-icon" tabIndex={0} onClick={handleClose} />

					<center>
						<Loader />
					</center>
				</div>
			) : isError ? (
				<div>
					<CloseModal className="close-icon" tabIndex={0} onClick={handleClose} />
					<center>
						<div>
							<div>
								<ProjectEmpty />
								<p>Unable to fetch post details at the moment</p>
							</div>
						</div>
					</center>
				</div>
			) : (
				<div>
					<div className="header">
						<div>
							<h4>{blog?.title}</h4>
						</div>

						<div className="btn-group">
							<Button variant="secondary" onClick={() => handleEdit(blog?.id || '')}>
								Edit
							</Button>
							<Link to={Approutes.dashboard.use_blog(blog?.id)}>
								<Button>View Preview</Button>
							</Link>
						</div>
					</div>

					<article className="flexed">
						<div>
							<b>Slug</b>
							<p>{blog?.slug}</p>
						</div>
						<div>
							<b>Status</b>
							<p className={blog?.is_published ? 'published' : 'draft'}>{blog?.is_published ? 'Published' : 'Draft'}</p>
						</div>
					</article>
					<article className="flexed">
						<div>
							<b>Author</b>
							<p>{blog?.author}</p>
						</div>
						<div>
							<b>Category</b>
							<p>{blog?.category}</p>
						</div>
					</article>
					<article>
						<b>Snippet</b>
						<p>{blog?.snippet}</p>
					</article>
					<article>
						<b>Thumbnail</b>
						<img src={blog?.thumbnail} alt="" />
					</article>
				</div>
			)}
		</Container>
	);
};
export default ViewBlogModal;
