import styled from 'styled-components';
import { maxQuery, minQuery } from '../../../../utilities';
import { StyledPagination } from '../../../../ui/Pagination/styles';

export const Container = styled.div`
	padding: 2.5rem 6.25rem;
	padding: 2.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);

	.header {
		margin-bottom: 1.5rem;

		h4 {
			color: #11071a;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.5rem;
			font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
			font-weight: 700;
			margin-bottom: 0.25rem;
			line-height: 120%;
		}
		p {
			color: #828282;
			font-size: 0.75rem;
			line-height: 120%;
		}
	}

	center {
		height: calc(100vh - 24rem);
		display: grid;
		place-items: center;
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
	}
	.error-message {
		text-align: center;
		font-weight: 600;
		padding: 0.5rem 0;
	}
	.empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 24rem);
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
	}
	.table-container {
		overflow: auto;

		${maxQuery('lg')} {
			display: none;
		}
	}
	table {
		width: 100%;
		min-width: max-content;
		border-collapse: collapse;

		> * > tr > *:first-child {
			text-align: center;
		}
		${minQuery('300px')} {
			> * > tr > *:first-child {
				left: 0;
				position: sticky;
				text-align: center;
			}
		}

		thead {
			th {
				font-weight: 600;
				padding: 0.875rem 1rem;
				text-align: inherit;
				background-color: #fafafa;
			}
		}

		tbody {
			background-color: #fff;

			tr {
				:not(:last-child) {
					border-bottom: 1px solid #dde0e2;
				}
			}

			td {
				background-color: #fff;
				font-weight: 500;
				padding: 1.25rem 1rem;

				:last-child {
					display: flex;
					align-items: center;
					gap: 1rem;

					a {
						display: flex;
						align-items: center;
					}

					svg {
						cursor: pointer;

						path {
							fill: #4060ea;
						}
					}
				}
			}
		}
	}
	.mobile-card {
		background: #fff;
		border-radius: 0.25rem;
		padding: 0 1rem;
		display: none;

		:not(:last-child) {
			margin-bottom: 1rem;
		}

		${maxQuery('lg')} {
			display: block;
		}

		> div {
			padding: 0.8rem 0;
			padding: clamp(0.5rem, 0rem + 1.6vw, 0.8rem) 0;

			:not(:last-child) {
				border-bottom: 1px solid rgba(150, 150, 150, 0.11);
			}
			&.flexed {
				display: flex;
				justify-content: space-between;
			}

			.action-con {
				display: flex;
				align-items: center;
				padding: 0 0.5rem;
				cursor: pointer;

				svg path {
					fill: #4060ea;
				}
			}

			> div:last-child {
				text-align: right;
			}
		}

		b {
			color: #323232;
			font-size: 0.8125rem;
			font-size: clamp(0.8125rem, 0.5rem + 1vw, 1rem);
			font-weight: 600;
		}
		p {
			color: rgba(15, 15, 15, 0.46);
			font-size: 0.8125rem;
			font-size: clamp(0.8125rem, 0.5rem + 1vw, 1rem);
		}
	}
	.dropdown-con {
		position: relative;

		:focus-within ul {
			display: block;
		}
		:focus-within button {
			background: #4060ea;

			path {
				fill: #fff;
			}
		}

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 2px 6px;
			background: #f1f4fd;
			border-radius: 2px;
		}

		ul {
			background: #fff;
			box-shadow: 0 0 4px #e4e7ec;
			overflow: auto;
			border-radius: 0.25rem;
			position: absolute;
			right: 0;
			width: max-content;
			z-index: 3;
			display: none;
			max-height: 270px;
			top: calc(100% + 0.5rem);
			min-width: 72px;

			&.top {
				top: unset;
				bottom: calc(100% + 0.5rem);
			}

			li {
				text-align: center;
				font-size: 0.875rem;
				cursor: pointer;
				outline: none;
				padding: 0.5rem 1rem;

				:hover,
				:focus {
					background: #f9fafb;
				}
				&.active {
					background: #ecf1fe;
				}
				&.empty-option {
					color: transparent;
				}

				a {
					justify-content: center;
				}
			}
		}
	}
	${StyledPagination} {
		justify-content: center;
		margin-top: 2rem;
	}
`;
