import { FileDownloadGroup } from '../../../../ui';

const EducationAndExperience = ({ user }) => {
	return (
		<>
			<article className="flexed">
				<div>
					<b>Highest Level of Education</b>
					<p>{user?.highest_edu_level}</p>
				</div>
				<div>
					<b>Area of Study</b>
					<p>{user?.area_of_study}</p>
				</div>
			</article>

			<article>
				<b>Institution</b>
				<p>{user?.recent_institution}</p>
			</article>

			<article className="attachments">
				<b>Institution ID Card/Certification Document</b>

				<div>
					{user?.id_card?.[0]?.url ? (
						user?.id_card?.[0]?.url?.endsWith('.png') ||
						user?.id_card?.[0]?.url?.endsWith('.jpeg') ||
						user?.id_card?.[0]?.url?.endsWith('.jpg') ? (
							<img className="id-card-img" src={user?.id_card?.[0]?.url} alt="" />
						) : (
							<FileDownloadGroup
								fileName={user?.id_card?.[0]?.file_name}
								url={user?.id_card?.[0]?.url}
								fileSizeInKb={user?.id_card?.[0]?.file_size_in_kb}
							/>
						)
					) : (
						'N/A'
					)}
				</div>
			</article>
		</>
	);
};
export default EducationAndExperience;
