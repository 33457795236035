import { useState } from 'react';
import { Button, Loader, PalletList } from '../../../../ui';
import { Container } from './styles';
import { DashboardSuitcase, DashboardVerify, DashboardStatus } from '../../../../assets/svgs';
import { useParams } from 'react-router-dom';
import {
	useApproveInternProfile,
	// useGetAuditLogsAsAdmin,
	useGetDashboardUser,
	useGetDashboardUserApprovalLogs,
	useNotify,
	useRejectInternProfile,
} from '../../../../hooks';
import { useQueryClient } from '@tanstack/react-query';
import { ProfileApproval } from '../../modals';
import { handleApiError } from '../../../../utilities';
import { AxiosError } from 'axios';
import { ApprovalLogs } from '../../components';
import Details from './Details';
import AuditLogs from './AuditLogs';

const Intern = () => {
	const notify = useNotify();
	const queryClient = useQueryClient();
	const [control, setControl] = useState<'details' | 'approval_logs' | 'trail' | 'feedbacks' | 'jobs'>('details');
	const [approvalMode, setApprovalMode] = useState<'REJECTING' | 'APPROVING'>('REJECTING');
	const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);

	const { id } = useParams();

	const { data: user, isLoading, refetch } = useGetDashboardUser({ user_id: id, user: 'interns' });
	// const { data: userAuditLogs, isLoading: isGettingAuditLogs } = useGetAuditLogsAsAdmin({ user_id: id });
	const { data: approvalLogs, refetch: refetchLogs } = useGetDashboardUserApprovalLogs({ user_id: id });
	const { mutate: approveIntern, isLoading: isApproving } = useApproveInternProfile({ user_id: id || '' });
	const { mutate: rejectIntern, isLoading: isRejecting } = useRejectInternProfile({ user_id: id || '' });

	const statsData = [
		{
			title: 'Internship Completed',
			body: 'Not Available',
			icon: <DashboardSuitcase />,
		},
		{
			title: 'Plan',
			body: user?.business_plan?.join(', ') || 'N/A',
			icon: <DashboardVerify />,
		},
		{
			title: 'Status',
			body: (
				<span className={user?.intern_profile_status?.toLowerCase()}>
					{user?.intern_profile_status?.toLowerCase()?.split('_')?.join(' ') || ''}
				</span>
			),
			icon: <DashboardStatus />,
		},
	];

	const handleApprovingClick = () => {
		setIsReasonModalOpen(true);
		setApprovalMode('APPROVING');
	};

	const handleDeclineClick = () => {
		setIsReasonModalOpen(true);
		setApprovalMode('REJECTING');
	};

	const handleApproving = (reason: string) => {
		approveIntern(
			{ is_intern_profile_approved: true, reason },
			{
				onSuccess: () => {
					notify({ message: 'Intern has been approved', status: 'success', toastOptions: { toastId: 'intern_profile_approved_success' } });
					refetch();
					refetchLogs();

					queryClient.invalidateQueries(['dashboard-stats']);
					queryClient.invalidateQueries(['dashboard-intern-stats']);
					queryClient.invalidateQueries(['dashboard-business-stats']);
					queryClient.invalidateQueries({ queryKey: ['dashboard-users'] });

					setIsReasonModalOpen(false);
				},
				onError: (error) =>
					notify({
						message: handleApiError(error as AxiosError),
						status: 'error',
						toastOptions: { toastId: 'intern_profile_approved_failure' },
					}),
			}
		);
	};

	const handleDecline = (reason: string) => {
		rejectIntern(
			{ reason },
			{
				onSuccess: () => {
					notify({ message: 'Intern has been rejected', status: 'success', toastOptions: { toastId: 'intern_profile_rejected_success' } });
					refetch();
					refetchLogs();

					queryClient.invalidateQueries(['dashboard-stats']);
					queryClient.invalidateQueries(['dashboard-intern-stats']);
					queryClient.invalidateQueries(['dashboard-business-stats']);
					queryClient.invalidateQueries({ queryKey: ['dashboard-users'] });

					setIsReasonModalOpen(false);
				},
				onError: (error) =>
					notify({
						message: handleApiError(error as AxiosError),
						status: 'error',
						toastOptions: { toastId: 'intern_profile_rejected_failure' },
					}),
			}
		);
	};

	return (
		<>
			<Container>
				<div className="header">
					<div>
						<h4>{user?.name}</h4>
						{/* <p>Update your photo and personal details</p> */}
					</div>

					<div className="btn-group">
						<Button variant="secondary" loading={isRejecting} disabled={user?.intern_profile_status === 'DECLINED'} onClick={handleDeclineClick}>
							Decline
						</Button>
						<Button loading={isApproving} disabled={user?.intern_profile_status === 'APPROVED'} onClick={handleApprovingClick}>
							Approve
						</Button>
					</div>
				</div>

				<PalletList loading={false} data={statsData} />

				<div className="controls">
					<button className={control === 'details' ? 'active' : ''} onClick={() => setControl('details')}>
						Intern Details
					</button>
					<button className={control === 'approval_logs' ? 'active' : ''} onClick={() => setControl('approval_logs')}>
						Approval Logs
					</button>
					<button className={control === 'trail' ? 'active' : ''} onClick={() => setControl('trail')}>
						Audit Trail
					</button>
					{/* <button className={control === 'feedbacks' ? 'active' : ''} onClick={() => setControl('feedbacks')}>
                            Feedbacks
                        </button>
                        <button className={control === 'jobs' ? 'active' : ''} onClick={() => setControl('jobs')}>
                            Jobs
                        </button> */}
				</div>

				{isLoading ? (
					<center>
						<Loader />
					</center>
				) : (
					<div className="content">
						{control === 'details' ? (
							<Details user={user} />
						) : control === 'approval_logs' ? (
							<ApprovalLogs status={user?.intern_profile_status} approvalLogs={approvalLogs} />
						) : control === 'trail' ? (
							<AuditLogs id={id || ''} />
						) : null}
					</div>
				)}
			</Container>

			{isReasonModalOpen ? (
				<ProfileApproval
					mode={approvalMode}
					isSubmitting={isApproving || isRejecting}
					handleSubmit={approvalMode === 'APPROVING' ? handleApproving : handleDecline}
					handleClose={() => setIsReasonModalOpen(false)}
				/>
			) : null}
		</>
	);
};

export default Intern;
