import { useRef, useState } from 'react';
import { Container } from './styles';
import { Button, Loader, Pagination } from '../../../../ui';
import { useDeleteBlog, useGetBlogs, useNotify, usePatchBlogs } from '../../../../hooks';
import { ButtonPlus, ProjectEmpty, TableThreeDots } from '../../../../assets/svgs';
import { AddBlogModal, EditBlogModal, ViewBlogModal } from '../../modals';

const Blogs = () => {
	const pageSize = useRef(10);
	const notify = useNotify();
	const [page, setPage] = useState(1);
	const [isAddBlogModalOpen, setIsAddBlogModalOpen] = useState(false);
	const [isEditingBlogsId, setIsEditingBlogsId] = useState<string | number>('');
	const [isViewingBlogId, setIsViewingBlogId] = useState<string | number>('');
	const [isEditingFor, setIsEditingFor] = useState<string | number>('');

	const { data: blogs, isLoading, isError, refetch } = useGetBlogs({ page, page_size: pageSize.current, search: '' });
	const { mutate: editBlog, isLoading: isEditing } = usePatchBlogs();
	const { mutate: deleteBlog, isLoading: isDeleting } = useDeleteBlog();

	const handlePublish = (id: string | number) => {
		if (isDeleting && isEditingFor === id) return;

		setIsEditingFor(id);

		editBlog(
			{ id, body: { is_published: true } },
			{
				onSuccess: () => {
					notify({
						message: `The post has been published`,
						status: 'success',
						toastOptions: { toastId: `publish_post_success-${id}` },
					});
					refetch();
					setIsEditingFor('');
				},
			}
		);
	};
	const handleSaveAsDraft = (id: string | number) => {
		if (isDeleting && isEditingFor === id) return;

		setIsEditingFor(id);

		editBlog(
			{ id, body: { is_published: false } },
			{
				onSuccess: () => {
					notify({
						message: `The post has been unpublished`,
						status: 'success',
						toastOptions: { toastId: `unpublish_post_success-${id}` },
					});
					refetch();
					setIsEditingFor('');
				},
			}
		);
	};
	const handleDelete = (id: string | number) => {
		setIsEditingFor(id);

		deleteBlog(
			{ id },
			{
				onSuccess: () => {
					notify({
						message: `The post has been deleted`,
						status: 'success',
						toastOptions: { toastId: `delete_post_success-${id}` },
					});
					refetch();
					setIsEditingFor('');
				},
			}
		);
	};

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Blogs</h4>
				</div>

				<Button onClick={() => setIsAddBlogModalOpen(true)}>
					<ButtonPlus /> Add a new post
				</Button>
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<center>
					<div>
						<ProjectEmpty />
						<p className="error-message">Unable to fetch blog posts at the moment</p>
					</div>
				</center>
			) : !blogs?.results || blogs?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p className="error-message">No blog post to display</p>
				</div>
			) : (
				<>
					<div className="table-container">
						<table>
							<thead>
								<tr>
									<th>S/N</th>
									<th>Title</th>
									<th>Category</th>
									<th>Publishing Status</th>
								</tr>
							</thead>
							<tbody>
								{blogs?.results?.map((post, index) => (
									<tr key={post?.id}>
										<td>{index + 1}</td>
										<td>{post?.title}</td>
										<td>{post?.category}</td>
										<td className={post?.is_published ? 'published' : 'draft'}>{post?.is_published ? 'Published' : 'Draft'}</td>
										<td>
											<div className="dropdown-con">
												<button>
													<TableThreeDots />
												</button>

												<ul>
													<li tabIndex={0} onClick={() => setIsViewingBlogId(post?.id || '')}>
														View
													</li>
													<li tabIndex={0} onClick={() => setIsEditingBlogsId(post?.id || '')}>
														Edit
													</li>
													<li tabIndex={0} onClick={() => (post.is_published ? handleSaveAsDraft(post?.id) : handlePublish(post?.id))}>
														{isEditingFor === post?.id && isEditing ? (
															<span className="post-loader">
																<Loader />
															</span>
														) : post.is_published ? (
															'Save as Draft'
														) : (
															'Publish'
														)}
													</li>
													<li tabIndex={0} onClick={() => handleDelete(post?.id)} style={{ color: '#ea2d2e' }}>
														{isEditingFor === post?.id && isDeleting ? (
															<span className="post-loader">
																<Loader />
															</span>
														) : (
															'Delete'
														)}
													</li>
												</ul>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{blogs?.results?.map((post, index) => (
						<div key={post?.id} className="mobile-card">
							<div className="flexed">
								<div>
									<b>Title</b>
									<p>{post?.title}</p>
								</div>
								<div className="action-con">
									<div className="dropdown-con">
										<button>
											<TableThreeDots />
										</button>

										<ul className={index >= blogs?.results?.length - 1 ? 'top' : ''}>
											<li tabIndex={0} onClick={() => setIsViewingBlogId(post?.id || '')}>
												View
											</li>
											<li tabIndex={0} onClick={() => setIsEditingBlogsId(post?.id || '')}>
												Edit
											</li>
											<li tabIndex={0} onClick={() => (post.is_published ? handleSaveAsDraft(post?.id) : handlePublish(post?.id))}>
												{isEditingFor === post?.id && isEditing ? (
													<span className="post-loader">
														<Loader />
													</span>
												) : post.is_published ? (
													'Save as Draft'
												) : (
													'Publish'
												)}
											</li>
											<li tabIndex={0} onClick={() => handleDelete(post?.id)} style={{ color: '#ea2d2e' }}>
												{isEditingFor === post?.id && isDeleting ? (
													<span className="post-loader">
														<Loader />
													</span>
												) : (
													'Delete'
												)}
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="flexed">
								<div>
									<b>Category</b>
									<p>{post?.category}</p>
								</div>
								<div>
									<b>Title</b>
									<p className={post?.is_published ? 'published' : 'draft'}>{post?.is_published ? 'Published' : 'Draft'}</p>
								</div>
							</div>
						</div>
					))}

					<Pagination page={page} setPage={setPage} maxPages={Math.ceil(blogs?.total / pageSize.current)} disabled={isLoading} />
				</>
			)}

			{isAddBlogModalOpen ? <AddBlogModal handleClose={() => setIsAddBlogModalOpen(false)} /> : null}
			{isEditingBlogsId ? <EditBlogModal postId={isEditingBlogsId} handleClose={() => setIsEditingBlogsId('')} /> : null}
			{isViewingBlogId ? (
				<ViewBlogModal
					postId={isViewingBlogId}
					handleClose={() => setIsViewingBlogId('')}
					handleEdit={(id) => {
						setIsViewingBlogId('');
						setIsEditingBlogsId(id);
					}}
				/>
			) : null}
		</Container>
	);
};

export default Blogs;
