import styled from 'styled-components';
import { StyledButton } from '../../../../ui/Button/styles';

export const Container = styled.div`
	padding: 2.5rem 6.25rem;
	padding: 2.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		flex-wrap: wrap;
		margin-bottom: 1.5rem;

		h4 {
			color: #11071a;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.5rem;
			font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
			font-weight: 700;
			margin-bottom: 0.25rem;
			line-height: 120%;
		}
		p {
			color: #828282;
			font-size: 0.75rem;
			line-height: 120%;
		}
		.btn-group {
			display: flex;
			align-items: center;
			gap: 1rem;
		}
		${StyledButton} {
			padding: 1rem;
			line-height: 130%;
			font-size: 0.875rem;
			font-size: clamp(0.75rem, 0.6806rem + 0.2222vw, 0.875rem);
			font-weight: 700;
			margin-left: auto;

			span {
				display: flex;
				align-items: center;
				gap: 0.5rem;
			}
		}
	}
	center {
		height: calc(100vh - 24rem);
		display: grid;
		place-items: center;
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
	}
	.error-message {
		text-align: center;
		font-weight: 600;
		padding: 0.5rem 0;
	}
	.empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 24rem);
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
	}

	.blog-container {
		h1 {
			font-size: 33px;
			line-height: 40px;

			@media (max-width: 950px) {
				font-size: 29px;
				line-height: 38px;
			}
			@media (max-width: 520px) {
				font-size: 24px;
				line-height: 34px;
			}
		}
		.date-time {
			font-weight: 700;
			font-size: 14px;
			line-height: 15px;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			color: #64748b;
			margin: 20px 0;

			@media (max-width: 520px) {
				font-size: 12px;
				margin: 16px 0;
			}
		}
		header {
			text-align: center;
			margin-bottom: 24px;

			h6 {
				font-size: 16px;
				line-height: 19px;
				text-transform: uppercase;

				@media (max-width: 520px) {
					font-size: 14px;
				}
			}
		}
		img {
			max-width: 100%;
		}
		.hero-img-con {
			margin-bottom: 16px;
			display: grid;
			place-items: center;

			img {
				object-fit: cover;
				min-height: 190px;
			}
		}
		.divider {
			display: flex;
			gap: 5rem;

			@media (max-width: 1200px) {
				flex-direction: column;
			}

			section:last-child {
				width: 380px;
				flex-shrink: 0;

				@media (max-width: 1200px) {
					width: 100%;

					/* > *:first-child {
						display: none;
					} */
				}
			}
		}
		.content {
			/* border-bottom: 1px solid #e7eaee;
			padding-bottom: 80px; */

			article {
				&:not(:last-child) {
					margin-bottom: 2rem;
				}

				h5 {
					font-size: 23px;
					line-height: 40px;

					@media (max-width: 950px) {
						font-size: 21px;
						line-height: 35px;
					}
					@media (max-width: 520px) {
						font-size: 19px;
						line-height: 30px;
					}
				}
			}
			a {
				text-decoration: underline;
				color: #4060ea;
			}
			ul,
			ol {
				padding-left: 1rem;
			}
			ul {
				list-style-type: disc;
			}
			p {
				font-size: 1rem;
				line-height: 200%;
				margin: 1rem 0;
			}
		}
	}
`;
