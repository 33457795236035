import { useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';
import { AuditLogsType, ServerGetResponse } from '../types';

export const useGetAuditLogsAsAdmin = ({ user_id = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<AuditLogsType>> =>
			axiosPrivate.get(Appurls.dashboard.audit_logs.get_logs(user_id)).then((response) => response.data),
		queryKey: ['dashboard-audit-logs', { user_id }],
		enabled: user_id ? true : false,
	});
};
