import { useEffect, useRef } from 'react';
import { StyledFileGroup } from './styles';
import { useNotify } from '../../hooks';
import { FileDefault, InputInfo } from '../../assets/svgs';

const FileGroup = ({
	value = {
		name: '',
		file: null,
	},
	onChange,
	helpTextOne = 'Browse From your Device',
	helpTextTwo = '',
	label,
	name,
	moreInfo = '',
	icon = FileDefault,
	required = false,
	maxSize, // 1 === 1MB, 0.8 === 800kb
	openByDefault = false,
	...rest
}) => {
	const fileRef = useRef();
	const infoIconRef = useRef();
	const notify = useNotify();
	const Icon = icon;

	const handleChange = (event) => {
		let oneMB = 1048576;

		if (maxSize && event.target.files?.item(0).size > oneMB * maxSize) {
			notify({
				message: 'Max file size exceeded',
				status: 'error',
				variant: 'minor',
				toastOptions: { toastId: 'file_size', position: 'bottom-center' },
			});
			event.target.value = '';
		} else {
			onChange(null, name, { name: event.target.files?.item(0).name || '', file: event.target.files?.item(0) });
		}
	};

	useEffect(() => {
		if (!openByDefault) return;

		const timer = setTimeout(() => {
			infoIconRef.current?.focus();
		}, 1000);

		return () => clearTimeout(timer);
	}, [openByDefault]);

	return (
		<StyledFileGroup>
			{label ? (
				<label htmlFor={name}>
					{label} {required ? <span className="asterisk">*</span> : null}
					{moreInfo ? (
						<span className="input-info-con">
							<InputInfo ref={infoIconRef} className="info" tabIndex={0} />
							<span className="outer">
								<span>{moreInfo}</span>
							</span>
						</span>
					) : null}
				</label>
			) : null}

			<div className="file-group">
				<input type="file" name={name} ref={fileRef} onChange={handleChange} required={value.name ? false : required} {...rest} />

				<div className="icon-con">
					<Icon />
				</div>

				<div className="text-con">
					{value.name ? (
						<p className="selected" title={value.name}>
							{value.name}
						</p>
					) : (
						<>
							<p>{helpTextOne}</p>
							<span title={helpTextTwo}>{helpTextTwo}</span>
						</>
					)}
				</div>

				{value.name ? (
					<button
						className="remove"
						type="button"
						onClick={() => {
							fileRef.current.value = '';
							onChange(null, name, { name: '', file: null });
						}}
					>
						REMOVE
					</button>
				) : (
					<button type="button" onClick={() => fileRef.current.click()}>
						UPLOAD
					</button>
				)}
			</div>
		</StyledFileGroup>
	);
};

export default FileGroup;
