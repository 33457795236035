import { FileDownloadGroup } from '../../../../ui';
import { getDateTime } from '../../../../utilities';

const Details = ({ user }: { user: any }) => {
	return (
		<>
			<section>
				<article>
					<b>Brief Introduction About Yourself</b>
					<p>{user?.about}</p>
				</article>

				<article className="grid">
					<div>
						<b>Email</b>
						<p>{user?.email}</p>
					</div>
					<div>
						<b>Contact Number</b>
						<p>{user?.contact_number}</p>
					</div>
					<div>
						<b>Applicant Type</b>
						<p>{user?.applicant_type}</p>
					</div>
				</article>
			</section>
			<section>
				<article className="grid">
					<div>
						<b>Institution</b>
						<p>{user?.recent_institution}</p>
					</div>
					<div>
						<b>Highest Level of Education</b>
						<p>{user?.highest_edu_level}</p>
					</div>
					<div>
						<b>Area of Study</b>
						<p>{user?.area_of_study}</p>
					</div>
				</article>

				<article className="grid">
					<div>
						<b>English proficiency level</b>
						<p>{user?.english_proficiency_level}</p>
					</div>
					<div>
						<b>Preferred Internship Start Date</b>
						<p>{getDateTime(user?.start_date, 'mm dd yyyy') || 'N/A'}</p>
					</div>
					<div>
						<b>Internship Duration</b>
						<p>{user?.program_duration || 'N/A'}</p>
					</div>
				</article>
			</section>
			<section>
				<article>
					<div>
						<b>Interested Sectors</b>
						<p>{user?.interested_sector?.join(', ') || 'N/A'}</p>
					</div>
				</article>
				<article className="flexed">
					<div style={{ flex: 1 }}>
						<b>Interested Roles</b>
						<div className="role-list">
							{user?.interested_role?.map((role: string) => (
								<span key={role}>{role}</span>
							))}
						</div>
					</div>
					<div style={{ flex: 1 }}>
						<b>Skills</b>
						<div className="role-list" style={{ justifyContent: 'flex-end' }}>
							{user?.skills?.map((skill: string) => (
								<span key={skill}>{skill}</span>
							))}
						</div>
					</div>
				</article>
			</section>
			<section>
				<div className="doc-group">
					<article className="attachments">
						<b>Institution ID Card/Certification Document</b>

						<div>
							{user?.id_card?.[0]?.url ? (
								user?.id_card?.[0]?.url?.endsWith('.png') ||
								user?.id_card?.[0]?.url?.endsWith('.jpeg') ||
								user?.id_card?.[0]?.url?.endsWith('.jpg') ? (
									<img className="id-card-img" src={user?.id_card?.[0]?.url} alt="" />
								) : (
									<FileDownloadGroup
										fileName={user?.id_card?.[0]?.file_name}
										url={user?.id_card?.[0]?.url}
										fileSizeInKb={user?.id_card?.[0]?.file_size_in_kb}
									/>
								)
							) : (
								'N/A'
							)}
						</div>
					</article>

					<article className="attachments">
						<b>Certification Document</b>

						<div>
							{user?.certification_docs?.[0]?.url ? (
								<FileDownloadGroup
									fileName={user?.certification_docs?.[0]?.file_name}
									url={user?.certification_docs?.[0]?.url}
									fileSizeInKb={user?.certification_docs?.[0]?.file_size_in_kb}
								/>
							) : (
								'N/A'
							)}
						</div>
					</article>
				</div>

				<div className="doc-group">
					<article className="attachments">
						<b>Enrollment Document</b>

						<div>
							{user?.enrollment_doc?.[0]?.url ? (
								<FileDownloadGroup
									fileName={user?.enrollment_doc?.[0]?.file_name}
									url={user?.enrollment_doc?.[0]?.url}
									fileSizeInKb={user?.enrollment_doc?.[0]?.file_size_in_kb}
								/>
							) : (
								'N/A'
							)}
						</div>
					</article>

					<article className="attachments">
						<b>Supplimentary Document</b>

						<div>
							{user?.supplimentary_docs?.[0]?.url ? (
								<FileDownloadGroup
									fileName={user?.supplimentary_docs?.[0]?.file_name}
									url={user?.supplimentary_docs?.[0]?.url}
									fileSizeInKb={user?.supplimentary_docs?.[0]?.file_size_in_kb}
								/>
							) : (
								'N/A'
							)}
						</div>
					</article>
				</div>
			</section>
		</>
	);
};
export default Details;
