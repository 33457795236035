import styled, { keyframes } from 'styled-components';
import { maxQuery } from '../../../../utilities';

export const Container = styled.div`
	padding: 2.5rem 6.25rem;
	padding: 2.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);

	> .header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		gap: 1rem;
		margin: 0 0 1.87rem;
		margin: o 0 clamp(1rem, -0.45rem + 4.64vw, 1.87rem);

		${maxQuery('md')} {
			flex-direction: column;
			align-items: flex-start;
		}

		h4 {
			color: #11071a;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.5rem;
			font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
			font-weight: 700;
			margin-bottom: 0.25rem;
			line-height: 120%;
		}
		p {
			color: #828282;
			font-size: 0.75rem;
			line-height: 120%;
		}
	}
	center {
		height: calc(100vh - 24rem);
		display: grid;
		place-items: center;
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
	}
	.empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 24rem);
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
	}

	.stat-group {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1.5rem;
	}
	.grid-con {
		border-radius: 10px;
		border: 1px solid #e5e5e5;
		padding: 1.5rem;
		gap: 1.5rem;
		display: grid;
		grid-template-columns: 1fr 1fr;

		> div:nth-child(3) {
			grid-column: 1/3;
		}
	}
	.chart-con {
		border-radius: 10px;
		border: 1px solid #e5e5e5;
		overflow: hidden;

		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1rem;
			border-bottom: 1px solid #e5e5e5;

			h4 {
				color: #11071a;
				font-family: 'Urbanist', sans-serif;
				font-size: 1.25rem;
				font-weight: 600;
				line-height: 120%;
			}
			a {
				font-size: 1rem;
				font-weight: 500;
				line-height: 120%;
				text-decoration-line: underline;
				color: #4060ea;
			}
		}

		.content {
			display: grid;
			background-color: #fff;
			grid-template-columns: 1fr 150px;
			padding: 1.5rem;

			p {
				color: #828282;
				letter-spacing: 0.06rem;
				font-size: 0.825rem;
				text-transform: uppercase;

				:not(:last-child) {
					margin-bottom: 1.5rem;
				}
			}
			b {
				color: #11071a;
				font-size: 1.125rem;
				font-weight: 700;
			}
		}
	}
`;

const loading = keyframes`
		100% {
			background-position: -100% 0%;
		}
	`;

export const CustomPallet = styled.div`
	border-radius: 0.125rem;
	background: #fff;
	padding: 2rem;
	width: max-content;
	width: 100%;

	&.loading {
		background-color: rgba(255, 255, 255, 0.8);

		h6,
		p,
		.img {
			background: linear-gradient(90deg, #e7e5e5 -24.18%, #f9f8f8 50.26%, #e7e5e5 114.84%);
			display: block;
			animation: ${loading} 1s infinite linear;
			background-size: 200% 100%;
			background-position: 100% 0%;
		}
		h6,
		p {
			height: 1rem;
			border-radius: 0.375rem;
		}
		h6 {
			width: 8rem;
		}
		p {
			width: 6rem;
		}
	}

	> div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1.5rem;
	}

	.img {
		width: 4rem;
		height: 4rem;
		display: grid;
		place-items: center;

		img,
		svg {
			width: 100%;
			height: auto;
		}
	}
	h6 {
		color: #828282;
		font-size: 1rem;
		letter-spacing: 0.06rem;
		font-weight: 400;
		font-family: 'Urbanist', sans-serif;
		margin-bottom: 1rem;
	}
	p {
		color: #11071a;
		font-size: 1.5rem;
		font-weight: 700;
	}
	span {
		font-size: 0.75rem;
		margin-top: 0.5rem;
		display: block;
		color: #828282;
	}
`;
