import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loader } from '../../../ui';
import { Container } from './styles';
import { useNotify } from '../../../hooks';
import { Approutes, Appurls, queryStrings } from '../../../constants';
import { handleApiError } from '../../../utilities';
import { axios } from '../../../library';
import { useEffect } from 'react';

const AcceptInviteAsSupervisor = () => {
	const notify = useNotify();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		axios
			.post(Appurls.auth.accept_supervisor_invite, { otp: searchParams.get(queryStrings.supervisor_token) || '' })
			.then(() => {
				notify({
					message: 'You can now login with your default password',
					status: 'success',
					toastOptions: { toastId: 'accept_invite_success' },
				});
			})
			.catch((error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'accept_invite_error' } }))
			.finally(() => navigate(Approutes.home));
	}, [navigate, notify, searchParams]);

	return (
		<Container>
			<Loader />
		</Container>
	);
};
export default AcceptInviteAsSupervisor;
