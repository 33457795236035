import styled from 'styled-components';
import { StyledButton } from '../../../../ui/Button/styles';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	inset: 0;
	z-index: 99;

	> div {
		border-radius: 0.25rem;
		background: #fff;
		padding: 2rem;
		padding: 2rem clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem);
		width: 60rem;
		max-width: calc(100vw - 2rem);
		max-height: calc(100vh - 4rem);
		overflow: auto;
		position: relative;

		.close-icon {
			margin: 1rem 0 0 auto;
			display: block;
			cursor: pointer;
			width: 1.25rem;
			width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

			:active {
				outline: none;
			}
		}
		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 1rem;
			margin-bottom: 1.5rem;

			h4 {
				color: #11071a;
				font-family: 'Urbanist', sans-serif;
				font-size: 1.5rem;
				font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
				font-weight: 700;
				margin-bottom: 0.25rem;
				line-height: 120%;
			}
			p {
				color: #828282;
				font-size: 0.75rem;
				line-height: 120%;
			}
			.btn-group {
				display: flex;
				align-items: center;
				gap: 1rem;
			}
			${StyledButton} {
				padding: 0.75rem 1rem;
				line-height: 130%;
				font-size: 0.875rem;
				font-size: clamp(0.75rem, 0.6806rem + 0.2222vw, 0.875rem);
				font-weight: 700;
				margin-left: auto;

				span {
					display: flex;
					align-items: center;
					gap: 0.5rem;
				}
			}
		}
		article {
			border-radius: 0.25rem;
			border: 1px solid rgba(18, 18, 18, 0.03);
			padding: 0.5rem;
			margin: 0.5rem 0;

			:last-child {
				margin-bottom: 0;
			}
			&.flexed {
				display: flex;
				justify-content: space-between;

				> div:nth-child(2) {
					text-align: right;
				}
			}

			b {
				display: block;
				margin-bottom: 0.63rem;
			}

			p {
				color: #070d19;
				font-size: 0.875rem;
				line-height: 140%;

				&.published {
					text-transform: capitalize;
					color: #017d34 !important;
				}
				&.draft {
					text-transform: capitalize;
					color: #c08030 !important;
				}
			}
			img {
				max-height: 250px;
				max-width: 100%;
			}
		}
	}
`;
