import { Outlet, useSearchParams, useLocation } from 'react-router-dom';
import { NotificationsModal } from '../../components';
import { Approutes, queryStrings } from '../../constants';
import { useDecodedUser, useUser } from '../../hooks';
import { getInitials, isIntern, isInternSupervisor, isStartUp } from '../../utilities';
import { OnlyLogout, GeneralHeader } from './Header';
import { UserType } from '../../types';

const ApplyLayout = () => {
	const user = useDecodedUser();
	const [searchParams] = useSearchParams();
	const { pathname } = useLocation();

	const { data: userDetails, isLoading } = useUser();
	let modifiedUserDetails = {};

	if (isStartUp(user as UserType)) {
		modifiedUserDetails = {
			initials: getInitials(userDetails?.company_name || ''),
			name: isLoading ? 'Loading...' : userDetails?.company_name || 'John Doe',
			role: userDetails?.sector || '',
			profile_image: userDetails?.logo,
		};
	} else if (isIntern(user as UserType)) {
		modifiedUserDetails = {
			initials: getInitials(userDetails?.name || ''),
			name: isLoading ? 'Loading...' : userDetails?.name || 'John Doe',
			role: 'Intern',
			profile_image: userDetails?.avatar,
		};
	} else if (isInternSupervisor(user as UserType)) {
		modifiedUserDetails = {
			initials: getInitials(userDetails?.supervisor_name || ''),
			name: isLoading ? 'Loading...' : userDetails?.supervisor_name || 'John Doe',
			role: 'Supervisor',
		};
	}

	return (
		<>
			{[Approutes.complete_registration].includes(pathname) ? <OnlyLogout /> : <GeneralHeader userDetails={modifiedUserDetails} />}

			{searchParams.get(queryStrings.notifications) ? <NotificationsModal /> : null}

			<Outlet />
		</>
	);
};

export default ApplyLayout;
