import styled from 'styled-components';

export const Container = styled.div`
	article {
		border-radius: 0.25rem;
		padding: 1.25rem 0;

		b {
			display: block;
			min-width: max-content;
			margin-bottom: 0.325rem;
		}

		p,
		li {
			color: #070d19;
			line-height: 140%;
			font-weight: 500;
		}
		ul {
			list-style-type: disc;
			padding-left: 1rem;

			li {
				margin-bottom: 0.75rem;

				b {
					display: inline-block;
					margin: 0;
				}
				p {
					padding: 0.5rem 0 0.25rem;
				}
				span {
					display: block;
					color: #a1a0a0;
				}
			}
		}
	}
`;
