import { AuditLogCancel, AuditLogCheckmark } from '../../../../assets/svgs';
import { useGetAuditLogsAsAdmin } from '../../../../hooks';
import { getDateTime } from '../../../../utilities';

const AuditLogs = ({ id }: { id: string }) => {
	const { data } = useGetAuditLogsAsAdmin({ user_id: id });

	return (
		<div className="audit-con">
			{data?.results?.map((log) => (
				<div>
					<div>{getDateTime(log.created_at, 'mm dd yyyy')} </div>
					<span>{log?.activity_type === 'PROFILE_REJECTED' ? <AuditLogCancel /> : <AuditLogCheckmark />}</span>
					<p>{log.description}</p>
				</div>
			))}
		</div>
	);
};
export default AuditLogs;
