import { useEffect, useRef, useState } from 'react';
import { Button, FileGroup, InputGroup, Loader, SelectGroup } from '../../../../ui';
import { CloseModal, FileImage, ProjectEmpty } from '../../../../assets/svgs';
import { Container } from './styles';
import { GeneralChangeEventType } from '../../../../types';
import { useQueryClient } from '@tanstack/react-query';
import { handleApiError } from '../../../../utilities';
import { AxiosError } from 'axios';
import { useGetBlog, useNotify, usePatchBlogs } from '../../../../hooks';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type EditBlogModalProp = {
	postId: string | number;
	handleClose: () => void;
};

const EditBlogModal = ({ postId, handleClose }: EditBlogModalProp) => {
	const errorRef = useRef<HTMLDivElement>(null!);
	const queryClient = useQueryClient();
	const notify = useNotify();

	const [postContent, setPostContent] = useState('');
	const [formData, setFormData] = useState({
		title: '',
		category: '',
		slug: '',
		author: 'Descinder',
		auto_generate: true,
		snippet: '',
		thumbnail: {
			name: '',
			file: null,
		},
	});

	const { data: blog, isLoading: isFetching, isError } = useGetBlog({ id: postId });
	const { mutate: editBlog, isLoading } = usePatchBlogs();

	useEffect(() => {
		setPostContent(blog?.body || '');
		setFormData((prev) => ({
			...prev,
			title: blog?.title || '',
			category: blog?.category || '',
			slug: blog?.slug || '',
			author: blog?.author || '',
			snippet: blog?.snippet || '',
			thumbnail: {
				name: blog?.thumbnail ? 'Thumbnail Image' : '',
				file: null,
			},
		}));
	}, [blog]);

	const formatToValidSlug = (value: string) => {
		return value
			.trim()
			.split('')
			.map((char) => (/\w/g.test(char) ? char : char === ' ' ? '-' : char === '-' ? char : ''))
			.join('')
			.toLowerCase();
	};

	const handleChange: GeneralChangeEventType = (event, name, value) => {
		errorRef.current.innerText = '';

		name = name || event?.target.name || '';
		value = value ?? event?.target.value ?? '';

		if ((name === 'title' && formData.auto_generate === true) || (name === 'auto_generate' && value === true)) {
			setFormData((prev) => ({
				...prev,
				slug: formatToValidSlug(name === 'title' ? (value as string) : formData.title),
			}));
		}
		if (name === 'slug') {
			setFormData((prev) => ({
				...prev,
				slug: formatToValidSlug(value as string),
			}));
		} else {
			setFormData((prev) => ({ ...prev, [name as string]: value }));
		}
	};
	const handleSubmit = (shouldPublish: boolean) => {
		if (
			formData.title.length === 0 ||
			formData.category.length === 0 ||
			formData.slug.length === 0 ||
			formData.author.length === 0 ||
			formData.snippet.length === 0 ||
			postContent.length === 0
		) {
			errorRef.current.innerText = 'One or more required field needs to be filled';
			return;
		}

		const axiosFormData = new FormData();

		formData.title && axiosFormData.append('title', formData.title);
		formData.category && axiosFormData.append('category', formData.category);
		formData.slug && axiosFormData.append('slug', formData.slug);
		formData.author && axiosFormData.append('author', formData.author);
		formData.snippet && axiosFormData.append('snippet', formData.snippet);
		postContent && axiosFormData.append('body', postContent);
		axiosFormData.append('is_published', shouldPublish ? '1' : '0');
		formData.thumbnail.file && axiosFormData.append('thumbnail', formData.thumbnail.file);

		editBlog(
			{ id: postId, body: axiosFormData },
			{
				onSuccess: () => {
					notify({
						message: shouldPublish ? 'Blog has been updated and published' : 'Blog has been updated and saved to drafts',
						status: 'success',
						toastOptions: { toastId: 'blog_update_success' },
					});

					queryClient.invalidateQueries(['blogs']);
					queryClient.invalidateQueries(['blog']);
					handleClose();
				},
				onError: (error) =>
					notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'blog_update_error' } }),
			}
		);
	};

	return (
		<Container>
			{isFetching ? (
				<form>
					<CloseModal className="close-icon" tabIndex={0} onClick={handleClose} />

					<center>
						<Loader />
					</center>
				</form>
			) : isError ? (
				<form>
					<CloseModal className="close-icon" tabIndex={0} onClick={handleClose} />
					<center>
						<div>
							<div>
								<ProjectEmpty />
								<p>Unable to fetch post details at the moment</p>
							</div>
						</div>
					</center>
				</form>
			) : (
				<form
					onSubmit={(event) => {
						event.preventDefault();
						handleSubmit(true);
					}}
				>
					<div className="form-header">
						<CloseModal tabIndex={0} onClick={handleClose} />
						<h2>Edit Blog Post</h2>
						<p>Update this blog post information</p>
						<hr />
					</div>
					<InputGroup label="Blog Title" name="title" onChange={handleChange} placeholder="Enter Blog Title" value={formData.title} required />

					<InputGroup
						label="Slug"
						name="slug"
						onChange={handleChange}
						placeholder="Enter Blog Slug"
						value={formData.slug}
						containerClassName="slug-con"
						required
						readOnly={formData.auto_generate === true}
						moreInfo="Only alphanumeric characters and underscores are valid"
						moreInfoPosition="topRight"
					/>

					<div className="checkbox-con">
						<InputGroup type="checkbox" name="auto_generate" onChange={handleChange} defaultChecked />
						<span>Auto-generate from title</span>
					</div>

					<div className="grouped-inputs">
						<InputGroup label="Author" name="author" onChange={handleChange} placeholder="Enter Author" value={formData.author} required />

						<SelectGroup
							label="Category"
							name="category"
							onChange={handleChange}
							options={['General', 'Interns', 'Startup', 'Institutions']}
							placeholder="Select a post category"
							value={formData.category}
							required
						/>
					</div>

					<FileGroup
						label="Thumbnail"
						name="thumbnail"
						onChange={handleChange}
						helpTextTwo="JPG or PNG. Max size of 5mb"
						accept="image/png, image/jpeg"
						maxSize={5}
						value={formData.thumbnail}
						icon={FileImage}
					/>

					<InputGroup
						label="Blog Snippet"
						name="snippet"
						onChange={handleChange}
						placeholder="Enter the post snippet"
						value={formData.snippet}
						variant="textarea"
						required
					/>
					<InputGroup
						label="Blog Content"
						name="content_label"
						onChange={handleChange}
						type="hidden"
						containerClassName="content-label-con"
						required
					/>
					<ReactQuill theme="snow" value={postContent} onChange={setPostContent} />

					<div className="error" ref={errorRef}></div>

					<div className="btn-group">
						<Button variant="secondary" type="button" loading={isLoading} onClick={() => handleSubmit(false)}>
							Update and Save as Draft
						</Button>
						<Button type="submit" loading={isLoading}>
							Update and Publish
						</Button>
					</div>
				</form>
			)}
		</Container>
	);
};
export default EditBlogModal;
