import { useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from '.';
import { Appurls } from '../constants';
import { MatchesType, ServerGetResponse } from '../types';

export const useGetMatches = ({ page = 1, page_size = 10, search = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<MatchesType>> =>
			axiosPrivate
				.get(`${Appurls.dashboard.matches.get_matches}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response.data),
		queryKey: ['dashboard-matches', { page, page_size, search }],
	});
};

export const useGetMatch = ({ match_id }: { match_id: string }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<MatchesType> => axiosPrivate.get(Appurls.dashboard.matches.get_match(match_id)).then((response) => response.data),
		queryKey: ['dashboard-match', { match_id }],
	});
};
