import { NavLink } from 'react-router-dom';
import { Approutes } from '../../../../constants';

const AdminLinks = ({ closeMobileNav }) => {
	return (
		<>
			<li>
				<NavLink to={Approutes.dashboard.index} end onClick={closeMobileNav}>
					Dashboard
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.dashboard.overview} end onClick={closeMobileNav}>
					Overview
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.dashboard.businesses} onClick={closeMobileNav}>
					Businesses
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.dashboard.interns.index} onClick={closeMobileNav}>
					Interns
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.dashboard.finances} onClick={closeMobileNav}>
					Finances
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.dashboard.matches} onClick={closeMobileNav}>
					Matches
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.dashboard.blogs} onClick={closeMobileNav}>
					Blogs
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.dashboard.coupons} onClick={closeMobileNav}>
					Coupons
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.dashboard.concerns} onClick={closeMobileNav}>
					Concerns
				</NavLink>
			</li>
		</>
	);
};
export default AdminLinks;
