import { Container, CustomPallet } from './styles';
import { PalletList } from '../../../../ui';
import { useGetMainDashboardStats } from '../../../../hooks';
import { Link } from 'react-router-dom';
import { Chart } from 'react-google-charts';
import {
	DashboardIntern,
	DashboardInstitution,
	DashboardAmountPaid,
	DashboardInterns,
	DashboardSuitcase,
	DashboardClipboard,
} from '../../../../assets/svgs';
import { Approutes } from '../../../../constants';

const Dashboard = () => {
	const result = useGetMainDashboardStats();
	const stats = { ...result?.[0]?.data?.data, ...result?.[1]?.data?.data };

	const statsLoading = result[0]?.isLoading || result[1]?.isLoading;

	const palletStatsData = [
		{
			title: 'Total Projects',
			body: stats?.project_count || 0,
			icon: <DashboardClipboard />,
		},
		{
			title: 'Total Users',
			body: stats?.user_count || 0,
			icon: <DashboardInterns />,
		},
		{
			title: 'Total Income',
			body: `£${stats?.income || 0}`,
			icon: <DashboardAmountPaid />,
		},
		{
			title: 'Total Internships',
			body: stats?.total_intenships || 0,
			icon: <DashboardSuitcase />,
		},
	];

	const chartData = [
		['Interns Category', 'Numbers'],
		['Awaiting Selection', stats.awaiting_selection],
		['In Project', stats.in_project],
	];

	const options = {
		legend: 'none',
		pieSliceText: 'label',
		title: '',
		pieStartAngle: 0,
	};

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Dashboard</h4>
					{/* <p>Update your photo and personal details</p> */}
				</div>
			</div>

			<PalletList loading={statsLoading} data={palletStatsData} />

			<section className="stat-group">
				<div className="grid-con">
					<CustomPallet className={statsLoading ? 'loading' : ''}>
						<h6>Startup interns</h6>
						<div>
							<div>
								<p>{stats.startup_interns}</p>
								{/* <span>+3.4%</span> */}
							</div>
							<div className="img">
								<DashboardIntern />
							</div>
						</div>
					</CustomPallet>
					<CustomPallet className={statsLoading ? 'loading' : ''}>
						<h6>Institution interns</h6>
						<div>
							<div>
								<p>{stats.institution_interns}</p>
								{/* <span>+3.4%</span> */}
							</div>
							<div className="img">
								<DashboardIntern />
							</div>
						</div>
					</CustomPallet>
					<CustomPallet className={statsLoading ? 'loading' : ''}>
						<h6>Total institution</h6>
						<div>
							<div>
								<p>{stats.institutions}</p>
								{/* <span>+3.4%</span> */}
							</div>
							<div className="img">
								<DashboardInstitution />
							</div>
						</div>
					</CustomPallet>
				</div>

				<div className="chart-con">
					<div className="header">
						<h4>Descinder Intern Demographic</h4>

						<Link to={Approutes.dashboard.interns.index}>View Interns</Link>
					</div>

					<div className="content">
						<div>
							<Chart chartType="PieChart" data={chartData} options={options} width={'100%'} height={'300px'} />;
						</div>
						<div className="info-con">
							<b>{stats.total_interns}</b>
							<p>Total Interns</p>
							<b>{stats.in_project}</b>
							<p>In Project Interns</p>
							<b>{stats.awaiting_selection}</b>
							<p>Awaiting Selection Interns</p>
							<b>{stats.rejected_interns}</b>
							<p>Rejected Interns</p>
						</div>
					</div>
				</div>
			</section>
		</Container>
	);
};
export default Dashboard;
