import styled from 'styled-components';
import { StyledButton } from '../../ui/Button/styles';
import { maxQuery } from '../../utilities';
import { StyledLoader } from '../../ui/Loader/styles';

export const Container = styled.div`
	padding: 2rem 6.25rem;
	padding: 2rem clamp(0rem, -3.4722rem + 11.1111vw, 6.25rem);

	header {
		display: flex;
		justify-content: space-between;
		padding-left: 2rem;
		padding-left: clamp(2rem, 3.1111rem + -3.5556vw, 0rem);

		${maxQuery('md')} {
			padding: 0 clamp(2rem, 3.1111rem + -3.5556vw, 0rem) 1rem;
		}

		> div {
			display: flex;
			flex-direction: column;
			justify-content: center;

			h4 {
				font-family: 'Urbanist', sans-serif;
				font-weight: 500;
				font-size: 1rem;
				font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
				line-height: 132%;
				color: #a8a8a8;
			}
			p {
				font-weight: 700;
				font-size: 2rem;
				font-size: clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem);
				line-height: 132%;
				color: #070d19;
				margin-top: 0.25rem;
			}
		}
		svg {
			margin-top: auto;
			height: auto;
			width: clamp(18.125rem, 14.7917rem + 6.6667vw, 20.625rem);

			${maxQuery('md')} {
				display: none;
			}
		}
	}

	section {
		border-radius: 0.25rem;
		background: #fff;
		box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);
		padding: 5rem 6.25rem;
		padding: clamp(2rem, 0.4043rem + 5.1064vw, 5rem) clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);
		display: grid;
		grid-template-columns: 240px 1fr;
		gap: 1rem;
		min-height: calc(100vh - (4rem + 76px));
		position: relative;

		${maxQuery('xl')} {
			display: block;
		}

		.back-btn {
			position: absolute;
			top: 5rem;
			top: clamp(1rem, -23rem + 32vw, 5rem);
			left: 6.25rem;
			left: clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);
			color: #252b33;
			font-size: 0.9375rem;
			font-weight: 600;
			border-radius: 0.25rem;
			border: 1px solid #ebebeb;
			background: #fff;
			padding: 1rem 1.5rem;
			display: inline-flex;
			align-items: center;
			gap: 1rem;

			svg {
				width: 0.8125rem;
				transform: rotate(90deg);
			}

			${maxQuery('xl')} {
				position: static;
				transform: translateY(-1.5rem);
			}
		}
		.steps-con {
			margin-top: 6.25rem;
			position: relative;
			height: max-content;

			::before {
				content: '';
				position: absolute;
				top: 0;
				left: 22.56px;
				width: 2px;
				height: 100%;
				background: #4060ea;
				z-index: 1;
			}
			${maxQuery('xl')} {
				display: flex;
				justify-content: space-between;
				gap: 6rem;
				overflow: hidden;
				margin-top: unset;
				margin-bottom: 2rem;

				::before {
					height: 2px;
					left: 0;
					top: 22.56px;
					width: 100%;
				}
			}

			.step {
				display: flex;
				gap: 1.1rem;
				position: relative;
				z-index: 2;
				background-color: #fff;
				padding: 0.5rem 0;

				:not(:last-child) {
					margin-bottom: 10rem;

					&.short {
						margin-bottom: 6rem;
					}

					${maxQuery('xl')} {
						&,
						&.short {
							margin-bottom: 0rem;
						}
					}
				}
				${maxQuery('xl')} {
					padding: 0 0.5rem;
					min-width: max-content;
				}
				&.active {
					b {
						color: #4060ea;
					}
					svg {
						:first-child {
							display: none;
						}
						:nth-child(2) {
							display: block;
						}
					}
				}
				svg:nth-child(2) {
					display: none;
				}

				b {
					color: #070d19;
					font-size: 1rem;
					line-height: 100%;
					display: block;
					margin-bottom: 0.5rem;
				}
				span {
					color: rgba(82, 80, 80, 0.4);
					font-size: 0.8125rem;
					font-weight: 600;
					text-transform: uppercase;
					display: block;
				}
			}
		}

		form {
			h2 {
				color: #070d19;
				font-size: 2.25rem;
				font-size: clamp(2rem, 1.8611rem + 0.4444vw, 2.25rem);
			}
			h2 + p {
				color: #a8a8a8;
				font-size: 1rem;
				font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
				line-height: 120%;
				border-bottom: 1px solid #ebebeb;
				margin: 0.5rem 0 2.5rem;
				padding-bottom: 1.5rem;
			}
			.cv-loader {
				position: fixed;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;
				z-index: 100;
				display: grid;
				place-items: center;
				background-color: rgba(0, 0, 0, 0.04);

				${StyledLoader} {
					border-width: 0.3rem;
				}
			}
			> div {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 1.5rem;

				${maxQuery('md')} {
					display: flex;
					flex-direction: column;
				}
			}
			.btn-group {
				margin-top: 3rem;
				grid-template-columns: 1fr 1fr;

				${maxQuery('md')} {
					gap: 0.5rem;
					display: flex;
					flex-wrap: wrap;
				}

				${maxQuery('350px')} {
					flex-direction: column;
				}

				${StyledButton} {
					justify-content: center;
					flex: 1;
				}
			}
			.experience-loader {
				display: grid;
				place-items: center;

				${StyledLoader} {
					width: 1.5rem;
					height: 1.5rem;
					border-width: 0.2rem;
				}
			}
			.employment-con {
				grid-column: 1/3;

				> div {
					display: grid;
					gap: 0.5rem;
					grid-template-columns: 1fr 1fr 1fr 6rem;

					:not(:last-child) {
						margin-bottom: 0.5rem;
					}

					label {
						color: #494848;
						font-weight: 500;
						font-size: 0.875rem;
						line-height: 120%;
						margin-bottom: 0.5rem;
						display: block;
					}

					input {
						display: block;
						width: 100%;
						padding: 1rem 1.5rem;
						font-family: inherit;
						font-size: 0.875rem;
						line-height: 130%;
						border-radius: 0.125rem;
						border: 1px solid rgba(229, 229, 229, 0.7);
						background: rgba(229, 229, 229, 0.1);
					}

					button {
						margin-left: 1rem;
						font-weight: 600;
						font-size: 14px;
						line-height: 160%;
						color: #000;
						display: flex;
						gap: 6px;
						align-items: center;
						background: transparent;

						:disabled {
							opacity: 0.5;
							cursor: not-allowed;
						}
					}
				}
				${StyledButton} {
					color: #4060ea;
					font-size: 0.825rem;
					padding: 0.75rem 1.5rem;
					border-radius: 0.25rem;
					background-color: transparent;
					margin-top: 0.5rem;

					span {
						display: flex;
						align-items: center;
						gap: 0.25rem;
					}
					svg {
						flex-shrink: 0;
					}
				}
			}
		}
	}
`;
