import { useEffect, useState } from 'react';
import { CloseModal, ProjectEmpty } from '../../../../assets/svgs';
import { PROFILE_STATUS, STARTUP_PAYMENT_PACKAGE, queryStrings } from '../../../../constants';
import { Button, Loader } from '../../../../ui';
import { Container } from './styles';
import { useSearchParams } from 'react-router-dom';
import { getDateTime, getPreviousSearchParams, handleApiError } from '../../../../utilities';
import {
	useApproveStartupProfile,
	useGetDashboardUser,
	useGetDashboardUserApprovalLogs,
	useNotify,
	useRejectStartupProfile,
} from '../../../../hooks';
import { useQueryClient } from '@tanstack/react-query';
import ProfileApproval from '../ProfileApproval';

const BusinessDetails = () => {
	const queryClient = useQueryClient();
	const notify = useNotify();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
	const [approvalMode, setApprovalMode] = useState('REJECTING');

	const businessId = searchParams.get(queryStrings.businessId) || '';

	const { data: user, isLoading, isError, refetch } = useGetDashboardUser({ user_id: businessId, user: 'businesses' });
	const { data: approvalLogs, isLoading: isLogsLoading, refetch: refetchLogs } = useGetDashboardUserApprovalLogs({ user_id: businessId });
	const { mutate: approveStartup, isLoading: isApproving } = useApproveStartupProfile({ user_id: businessId });
	const { mutate: rejectStartup, isLoading: isRejecting } = useRejectStartupProfile({ user_id: businessId });

	const handleClose = () => {
		let previousParams = getPreviousSearchParams(searchParams);
		delete previousParams[queryStrings.businessId];

		setSearchParams(previousParams, { replace: true });
	};
	const handleApprovingClick = () => {
		if (user?.startup_profile_status === PROFILE_STATUS.pending) {
			notify({
				message: 'Startup is yet to complete their profile',
				status: 'error',
				toastOptions: { toastId: 'startup_profile_pre_rejection_failure' },
			});

			return;
		}

		setIsReasonModalOpen(true);
		setApprovalMode('APPROVING');
	};
	const handleDeclineClick = () => {
		if (user?.startup_profile_status === PROFILE_STATUS.pending) {
			notify({
				message: 'Startup is yet to complete their profile',
				status: 'error',
				toastOptions: { toastId: 'startup_profile_pre_rejection_failure' },
			});

			return;
		}

		setIsReasonModalOpen(true);
		setApprovalMode('REJECTING');
	};

	const handleApproving = (reason) => {
		if (user?.startup_profile_status === PROFILE_STATUS.pending) {
			notify({
				message: 'Startup is yet to complete their profile',
				status: 'error',
				toastOptions: { toastId: 'startup_profile_pre_rejection_failure' },
			});

			return;
		}

		approveStartup(
			{ reason },
			{
				onSuccess: () => {
					notify({ message: 'Startup has been approved', status: 'success', toastOptions: { toastId: 'startup_profile_approved_success' } });
					refetch();
					refetchLogs();

					queryClient.invalidateQueries(['dashboard-stats']);
					queryClient.invalidateQueries(['dashboard-intern-stats']);
					queryClient.invalidateQueries(['dashboard-business-stats']);
					queryClient.invalidateQueries({ queryKey: ['dashboard-users'] });

					setIsReasonModalOpen(false);
				},
				onError: (error) =>
					notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'startup_profile_approved_failure' } }),
			}
		);
	};
	const handleDecline = (reason) => {
		if (user?.startup_profile_status === PROFILE_STATUS.awaiting_approval) {
			notify({
				message: 'Startup is yet to complete their profile',
				status: 'error',
				toastOptions: { toastId: 'startup_profile_pre_rejection_failure' },
			});

			return;
		}

		rejectStartup(
			{
				reason: reason,
			},
			{
				onSuccess: () => {
					notify({ message: 'Rejection successful', status: 'success', toastOptions: { toastId: 'user_profile_approved_success' } });
					refetch();
					refetchLogs();
					queryClient.invalidateQueries(['dashboard-stats']);
					queryClient.invalidateQueries(['dashboard-intern-stats']);
					queryClient.invalidateQueries(['dashboard-business-stats']);
					queryClient.invalidateQueries({ queryKey: ['dashboard-users'] });

					setIsReasonModalOpen(false);
				},
				onError: (error) =>
					notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'user_profile_approved_failure' } }),
			}
		);
	};

	useEffect(() => {
		if (businessId) return;

		let previousParams = getPreviousSearchParams(searchParams);
		delete previousParams[queryStrings.businessId];

		setSearchParams(previousParams, { replace: true });
	}, [businessId, searchParams, setSearchParams]);

	return (
		<>
			<Container>
				{isLoading || isLogsLoading ? (
					<center>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<div>
							<Loader />
						</div>
					</center>
				) : isError ? (
					<center>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<div>
							<div>
								<ProjectEmpty />
								<p>Unable to fetch business details at the moment</p>
							</div>
						</div>
					</center>
				) : (
					<div>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<section className="header">
							<div>
								<div className="img"></div>
								<div>
									<b>Company's Name</b>
									<p>{user?.company_name}</p>
								</div>
							</div>
							<div>
								<b>Business Plan</b>
								<p>
									{(typeof user?.business_plan !== 'string' &&
										user?.business_plan?.map((item) => STARTUP_PAYMENT_PACKAGE.find((packageItem) => packageItem.key === item)?.title).join(', ')) ||
										'N/A'}
								</p>
							</div>
						</section>

						<article>
							<b>Brief Company Introduction</b>
							<p>{user?.about}</p>
						</article>

						<article className="flexed">
							<div>
								<b>Email Address</b>
								<p>{user?.email}</p>
							</div>
							<div>
								<b>Company Contact Number</b>
								<p>{user?.company_phone}</p>
							</div>
						</article>

						<article className="flexed">
							<div>
								<b>Company Sector</b>
								<p>{user?.sector}</p>
							</div>
							<div>
								<b>Company Size</b>
								<p>{user?.size}</p>
							</div>
						</article>

						<article className="flexed">
							<div>
								<b>Approval Status</b>
								<p className={user?.startup_profile_status?.toLowerCase()}>{user?.startup_profile_status?.toLowerCase()?.split('_')?.join(' ')}</p>
							</div>
							<div>
								<b>Company Registration Number</b>
								<p>{user?.company_reg_no}</p>
							</div>
						</article>

						<article className="project">
							<div>
								<b>Subscription Start </b>
								<p>{getDateTime(user?.sub_start, 'mm dd yyyy') || 'N/A'}</p>
							</div>
							<div>
								<b>Subscription End</b>
								<p>{getDateTime(user?.sub_end, 'mm dd yyyy') || 'N/A'}</p>
							</div>
							<div>
								<b>Subscription Timeline</b>
								<p>
									{user?.sub_start && user?.sub_end
										? STARTUP_PAYMENT_PACKAGE.find((packageItem) => packageItem.key === user?.business_plan[0])?.duration || 'N/A'
										: 'N/A'}
								</p>
							</div>
						</article>

						<article className="flexed">
							<div>
								<b>Website url</b>
								<p>
									{user?.website_url ? (
										<a href={user?.website_url} target="_blank" rel="noreferrer">
											{user?.website_url}
										</a>
									) : (
										'N/A'
									)}
								</p>
							</div>

							<div>
								<b>Amount Paid</b>
								<p>{user?.amount_paid}</p>
							</div>
						</article>

						<article>
							<b>Approval Status logs</b>
							{approvalLogs?.length ? (
								<ul>
									{approvalLogs?.map((log) => (
										<li key={log?.id}>
											<b
												className={
													log?.title?.toLowerCase() === 'profile declined'
														? 'declined'
														: log?.title?.toLowerCase() === 'profile approved'
														? 'approved'
														: log?.title?.toLowerCase() === 'profile resubmitted'
														? 'pending'
														: ''
												}
											>
												{log?.title}
											</b>
											{log?.log ? <p>Reason: {log?.log}</p> : null}
											<span>
												({getDateTime(log?.created_at)} - {getDateTime(log?.created_at, null, false)})
											</span>
										</li>
									))}
								</ul>
							) : (
								<p>No logs</p>
							)}
						</article>

						{/* <article className="attachments">
						<b>Attachments</b>

						<FileDownloadGroup fileName={'Brief details.docx'} url={'details?.docs[0]?.url'} fileSizeInKb={1500} />
					</article> */}

						<div className="btn-group">
							<Button
								variant="secondary"
								loading={isRejecting}
								disabled={user?.startup_profile_status === 'DECLINED'}
								onClick={handleDeclineClick}
							>
								Decline
							</Button>
							<Button loading={isApproving} disabled={user?.startup_profile_status === 'APPROVED'} onClick={handleApprovingClick}>
								Approve
							</Button>
						</div>
					</div>
				)}
			</Container>

			{isReasonModalOpen ? (
				<ProfileApproval
					mode={approvalMode}
					isSubmitting={isApproving || isRejecting}
					handleSubmit={approvalMode === 'APPROVING' ? handleApproving : handleDecline}
					handleClose={() => setIsReasonModalOpen(false)}
				/>
			) : null}
		</>
	);
};
export default BusinessDetails;
