import { useEffect, useState } from 'react';
import { CloseModal, ProjectEmpty } from '../../../../assets/svgs';
import { PROFILE_STATUS, queryStrings } from '../../../../constants';
import { Button, Loader } from '../../../../ui';
import { Container } from './styles';
import { useSearchParams } from 'react-router-dom';
import { getPreviousSearchParams, handleApiError } from '../../../../utilities';
import {
	useApproveInternProfile,
	useGetDashboardUser,
	useGetDashboardUserApprovalLogs,
	useNotify,
	useRejectInternProfile,
} from '../../../../hooks';
import { useQueryClient } from '@tanstack/react-query';
import ProfileApproval from '../ProfileApproval';
import PersonalDetails from './PersonalDetails';
import EducationAndExperience from './EducationAndExperience';
import ApplicationDocs from './ApplicationDocs';
import { ApprovalLogs } from '../../components';

const InternDetails = () => {
	const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
	const [control, setControl] = useState('PERSONAL_DETAILS');
	const [approvalMode, setApprovalMode] = useState('REJECTING');

	const queryClient = useQueryClient();
	const notify = useNotify();
	const [searchParams, setSearchParams] = useSearchParams();

	const applicantId = searchParams.get(queryStrings.applicantId) || '';

	const { data: user, isLoading, isError, refetch } = useGetDashboardUser({ user_id: applicantId, user: 'interns' });
	const { data: approvalLogs, isLoading: isLogsLoading, refetch: refetchLogs } = useGetDashboardUserApprovalLogs({ user_id: applicantId });
	const { mutate: approveIntern, isLoading: isApproving } = useApproveInternProfile({ user_id: applicantId });
	const { mutate: rejectIntern, isLoading: isRejecting } = useRejectInternProfile({ user_id: applicantId });

	const handleClose = () => {
		let previousParams = getPreviousSearchParams(searchParams);
		delete previousParams[queryStrings.applicantId];

		setSearchParams(previousParams, { replace: true });
	};
	const handleApprovingClick = () => {
		if (user?.intern_profile_status === PROFILE_STATUS.pending) {
			notify({
				message: 'Intern is yet to complete their profile',
				status: 'error',
				toastOptions: { toastId: 'institutiom_profile_pre_rejection_failure' },
			});

			return;
		}

		setIsReasonModalOpen(true);
		setApprovalMode('APPROVING');
	};
	const handleDeclineClick = () => {
		if (user?.intern_profile_status === PROFILE_STATUS.pending) {
			notify({
				message: 'Intern is yet to complete their profile',
				status: 'error',
				toastOptions: { toastId: 'institutiom_profile_pre_rejection_failure' },
			});

			return;
		}

		setIsReasonModalOpen(true);
		setApprovalMode('REJECTING');
	};

	const handleApproving = (reason) => {
		if (user?.intern_profile_status === PROFILE_STATUS.pending) {
			notify({
				message: 'Intern is yet to complete their profile',
				status: 'error',
				toastOptions: { toastId: 'intern_profile_pre_rejection_failure' },
			});

			return;
		}

		approveIntern(
			{ is_intern_profile_approved: true, reason },
			{
				onSuccess: () => {
					notify({ message: 'Intern has been approved', status: 'success', toastOptions: { toastId: 'intern_profile_approved_success' } });
					refetch();
					refetchLogs();

					queryClient.invalidateQueries(['dashboard-stats']);
					queryClient.invalidateQueries(['dashboard-intern-stats']);
					queryClient.invalidateQueries(['dashboard-business-stats']);
					queryClient.invalidateQueries({ queryKey: ['dashboard-users'] });

					setIsReasonModalOpen(false);
				},
				onError: (error) =>
					notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'intern_profile_approved_failure' } }),
			}
		);
	};

	const handleDecline = (reason) => {
		if (user?.intern_profile_status === PROFILE_STATUS.awaiting_approval) {
			notify({
				message: 'Intern is yet to complete their profile',
				status: 'error',
				toastOptions: { toastId: 'intern_profile_pre_rejection_failure' },
			});

			return;
		}

		rejectIntern(
			{ reason },
			{
				onSuccess: () => {
					notify({ message: 'Intern has been rejected', status: 'success', toastOptions: { toastId: 'intern_profile_rejected_success' } });
					refetch();
					refetchLogs();

					queryClient.invalidateQueries(['dashboard-stats']);
					queryClient.invalidateQueries(['dashboard-intern-stats']);
					queryClient.invalidateQueries(['dashboard-business-stats']);
					queryClient.invalidateQueries({ queryKey: ['dashboard-users'] });

					setIsReasonModalOpen(false);
				},
				onError: (error) =>
					notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'intern_profile_rejected_failure' } }),
			}
		);
	};

	useEffect(() => {
		if (applicantId) return;

		let previousParams = getPreviousSearchParams(searchParams);
		delete previousParams[queryStrings.applicantId];

		setSearchParams(previousParams, { replace: true });
	}, [applicantId, searchParams, setSearchParams]);

	return (
		<>
			<Container>
				{isLoading || isLogsLoading ? (
					<center>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<div>
							<Loader />
						</div>
					</center>
				) : isError ? (
					<center>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<div>
							<div>
								<ProjectEmpty />
								<p>Unable to fetch applicant's details at the moment</p>
							</div>
						</div>
					</center>
				) : (
					<div>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<section className="header">
							<div>
								<div className="img"></div>
								<div>
									<b>Applicant's Name</b>
									<p>{user?.name}</p>
								</div>
							</div>
							<div>
								<b>Add-Ons Plan</b>
								<p>{user?.business_plan?.join(', ') || 'N/A'}</p>
							</div>
						</section>

						<div className="nav">
							<button className={control === 'PERSONAL_DETAILS' ? 'active' : ''} onClick={() => setControl('PERSONAL_DETAILS')}>
								Personal Details
							</button>
							<button className={control === 'EXPERIENCE_AND_EDUCATION' ? 'active' : ''} onClick={() => setControl('EXPERIENCE_AND_EDUCATION')}>
								Experience and education
							</button>
							<button className={control === 'APPLICATION_DOCS' ? 'active' : ''} onClick={() => setControl('APPLICATION_DOCS')}>
								Application Docs
							</button>
							<button className={control === 'APPROVAL_LOGS' ? 'active' : ''} onClick={() => setControl('APPROVAL_LOGS')}>
								Approval Logs
							</button>
						</div>

						{control === 'PERSONAL_DETAILS' ? (
							<PersonalDetails user={user} />
						) : control === 'EXPERIENCE_AND_EDUCATION' ? (
							<EducationAndExperience user={user} />
						) : control === 'APPLICATION_DOCS' ? (
							<ApplicationDocs user={user} />
						) : control === 'APPROVAL_LOGS' ? (
							<ApprovalLogs status={user?.intern_profile_status} approvalLogs={approvalLogs} />
						) : null}

						<div className="btn-group">
							<Button variant="secondary" loading={isRejecting} disabled={user?.intern_profile_status === 'DECLINED'} onClick={handleDeclineClick}>
								Decline
							</Button>
							<Button loading={isApproving} disabled={user?.intern_profile_status === 'APPROVED'} onClick={handleApprovingClick}>
								Approve
							</Button>
						</div>
					</div>
				)}
			</Container>

			{isReasonModalOpen ? (
				<ProfileApproval
					mode={approvalMode}
					isSubmitting={isApproving || isRejecting}
					handleSubmit={approvalMode === 'APPROVING' ? handleApproving : handleDecline}
					handleClose={() => setIsReasonModalOpen(false)}
				/>
			) : null}
		</>
	);
};
export default InternDetails;
