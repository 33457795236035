import styled from 'styled-components';
import { maxQuery } from '../../../../utilities';
import { StyledButton } from '../../../../ui/Button/styles';

export const Container = styled.div`
	padding: 2.5rem 6.25rem;
	padding: 2.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);

	.header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		gap: 1rem;
		margin: 0 0 1.87rem;
		margin: o 0 clamp(1rem, -0.45rem + 4.64vw, 1.87rem);

		${maxQuery('md')} {
			flex-direction: column;
			align-items: flex-start;
		}

		h4 {
			color: #11071a;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.5rem;
			font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
			font-weight: 700;
			margin-bottom: 0.25rem;
			line-height: 120%;
		}
		p {
			color: #828282;
			font-size: 0.75rem;
			line-height: 120%;
		}

		.btn-group {
			display: flex;
			gap: 1rem;

			${StyledButton} {
				padding: 0.6rem clamp(1.8rem, 1.1rem + 2.24vw, 2.5rem);

				&:first-child {
					color: #ea2d2e;
					border-color: #ea2d2e;
				}
			}
		}
	}

	.declined {
		font-size: inherit;
		text-transform: capitalize;
		color: #ea2d2e !important;
	}
	.approved {
		font-size: inherit;
		text-transform: capitalize;
		color: #017d34 !important;
	}
	.pending {
		font-size: inherit;
		text-transform: capitalize;
		color: #c08030 !important;
	}
	.awaiting_approval {
		font-size: inherit;
		text-transform: capitalize;
		color: #c08030 !important;
	}

	.controls {
		display: flex;
		flex-wrap: wrap;
		gap: 0 1rem;
		margin-bottom: 1rem;

		button {
			background-color: transparent;
			color: #bbbaba;
			font-size: 1rem;
			font-weight: 600;
			border-bottom: 2px solid transparent;
			padding: 0.5rem 1rem 0.5rem 0;

			&.active {
				color: #12091b;
				border-bottom: 2px solid #4060ea;
			}
		}
	}

	.content {
		background-color: #fff;
		border-radius: 0.25rem;
		padding: 1rem;
	}
	section {
		border-radius: 0.25rem;
		border: 1px solid #ebebeb;
		padding: 1rem;
		margin-bottom: 1rem;
	}
	article {
		&:not(:last-child) {
			padding-bottom: 1.5rem;
		}
		&.flexed {
			display: flex;
			justify-content: space-between;

			> div:nth-child(2) {
				text-align: right;
			}
		}
		&.grid {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
		}
		&.project {
			display: flex;
			justify-content: space-between;
			text-align: center;
		}

		b {
			display: block;
			min-width: max-content;
			margin-bottom: 0.325rem;
		}

		p,
		li {
			color: #070d19;
			line-height: 140%;
			font-weight: 500;
		}

		.id-card-img {
			max-width: 100%;
			max-height: 250px;
		}
	}
	.role-list {
		display: flex;
		flex-wrap: wrap;
		gap: 0.35rem;
		margin-top: 1rem;
		padding: 0 1rem;

		span {
			border-radius: 0.125rem;
			background: #f4f5f9;
			color: #2f2f2f;
			font-size: 0.625rem;
			font-weight: 500;
			line-height: 132%;
			padding: 0.5rem 0.63rem;
			text-transform: capitalize;
		}
	}
	.doc-group {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding-bottom: 1.5rem;
		gap: 1rem;

		${maxQuery('lg')} {
			flex-direction: column;
		}

		> article {
			padding-bottom: 0 !important;

			&:nth-child(2) {
				text-align: right;

				${maxQuery('lg')} {
					text-align: left;
				}
			}
		}
	}
	.audit-con {
		> div {
			--gap: 1.5rem;

			display: flex;
			gap: var(--gap);

			&:not(:last-child) {
				margin-bottom: 1.5rem;
			}
			&:last-child span::before {
				display: none;
			}

			> div {
				min-width: max-content;
			}
			> span {
				position: relative;

				::before {
					content: '';
					position: absolute;
					left: 50%;
					top: 0.85rem;
					width: 1px;
					background-color: #bbbaba;
					height: calc(var(--gap) + 100%);
					z-index: 1;
				}

				svg {
					background-color: #fff;
					position: relative;
					margin-top: 0.35rem;
					z-index: 2;
				}
			}
		}
	}
`;
