import styled from 'styled-components';
import { maxQuery } from '../../../../utilities';

const screenBreak = 1400;

type ContainerProps = {
	$breakAtScreen?: number;
};
export const Container = styled.header<ContainerProps>`
	position: sticky;
	top: 0;
	z-index: 50;
	background: #fff;
	padding: 0.5rem 6.25rem 0.25rem;
	padding: 0.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);
	max-height: 108px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

	${({ $breakAtScreen }) => ($breakAtScreen ? maxQuery($breakAtScreen) : maxQuery(screenBreak))} {
		&.active {
			background: #070d19;
			position: fixed;
			inset: 0;
			max-height: unset;

			.logo-con {
				> svg:nth-child(1) {
					display: none;
				}
				> svg:nth-child(2) {
					display: block;
				}
			}
			.control-con {
				.ham-con {
					display: none;
				}
				> svg {
					display: block;
				}
			}
			ul,
			.last-section {
				display: flex;

				.bell-con span {
					border-color: #000;
				}
			}
			> div {
				padding-bottom: 3rem;
			}
		}
	}

	> div {
		display: grid;
		align-items: center;
		grid-template-columns: 2fr 5fr 2fr;

		${({ $breakAtScreen }) => ($breakAtScreen ? maxQuery($breakAtScreen) : maxQuery(screenBreak))} {
			overflow: auto;
			min-height: 100%;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: max-content;
		}
	}
	.control-con {
		display: none;
		cursor: pointer;
		width: max-content;
		margin-left: auto;

		${({ $breakAtScreen }) => ($breakAtScreen ? maxQuery($breakAtScreen) : maxQuery(screenBreak))} {
			display: block;
		}

		.ham-con {
			position: relative;
			display: grid;
			height: fit-content;

			&.unread::after {
				content: '';
				position: absolute;
				top: 0.25rem;
				right: 0rem;
				width: 0.75rem;
				height: 0.75rem;
				background-color: #4060ea;
				border-radius: 50%;
				outline: 3px solid #fff;
			}
		}
		svg:active {
			outline: none;
		}

		.close:hover path {
			fill: #4060ea;
		}
	}
	ul {
		display: flex;
		justify-content: center;

		${({ $breakAtScreen }) => ($breakAtScreen ? maxQuery($breakAtScreen) : maxQuery(screenBreak))} {
			gap: 0.5rem;
			grid-column: 1/3;
			flex-direction: column;
			margin: 4rem 0;
			align-self: flex-start;
			display: none;
		}

		a {
			padding: 1.25rem;
			color: #757575;
			font-size: 1rem;
			font-weight: 500;
			line-height: 132%;
			border-bottom: 2px solid transparent;
			position: relative;

			&.unread::after {
				content: '';
				position: absolute;
				top: 50%;
				right: 0rem;
				width: 0.5rem;
				height: 0.5rem;
				transform: translateY(-50%);
				background-color: #4060ea;
				border-radius: 50%;
			}

			${({ $breakAtScreen }) => ($breakAtScreen ? maxQuery($breakAtScreen) : maxQuery(screenBreak))} {
				display: block;
				padding: 0.5rem 1rem;
				color: #eff3fb;

				&.unread::after {
					background-color: #eff3fb;
					left: 6rem;
					right: unset;
				}
				&.unread.active::after,
				&.unread:hover::after {
					background-color: #4060ea;
				}
			}

			&.active {
				color: #4060ea;
				border-bottom: 2px solid #4060ea;

				${({ $breakAtScreen }) => ($breakAtScreen ? maxQuery($breakAtScreen) : maxQuery(screenBreak))} {
					border-bottom: none;
				}
			}
			:hover {
				color: #4060ea;
			}
		}
	}

	.logo-con {
		display: grid;
		width: max-content;

		svg {
			flex-shrink: 0;
			/* width: clamp(8.3rem, 6.1191rem + 6.9787vw, 12.4rem); */
			height: 60px;
			height: clamp(2.8125rem, 2.2917rem + 1.6667vw, 3.75rem);
			width: auto;
		}
	}
	.logo-con > svg:nth-child(2) {
		display: none;
	}
	.control-con > svg {
		display: none;
	}

	.last-section {
		display: flex;
		align-items: center;
		gap: 1rem;
		margin-left: auto;

		${({ $breakAtScreen }) => ($breakAtScreen ? maxQuery($breakAtScreen) : maxQuery(screenBreak))} {
			grid-column: 1/3;
			margin-left: unset;
			margin-top: auto;
			display: none;
		}

		.bell-con {
			position: relative;
			cursor: pointer;

			svg {
				width: 2.15rem;
				height: 2.15rem;

				${({ $breakAtScreen }) => ($breakAtScreen ? maxQuery($breakAtScreen) : maxQuery(screenBreak))} {
					path:first-child {
						fill: #fff;
					}
				}
			}

			span {
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				font-size: 0.75rem;
				font-weight: 700;
				line-height: 100%;
				letter-spacing: -0.00375rem;
				background-color: #4060ea;
				border: 2px solid #fff;
				width: 1.4rem;
				height: 1.4rem;
				border-radius: 50%;
				top: -0.33rem;
				right: -0.27rem;

				&.max {
					width: 1.5rem;
					height: 1.5rem;
				}
			}
		}
		.profile-con {
			display: flex;
			align-items: center;
			gap: 0.75rem;

			${({ $breakAtScreen }) => ($breakAtScreen ? maxQuery($breakAtScreen) : maxQuery(screenBreak))} {
				margin-right: auto;
			}

			img {
				width: 2.625rem;
				height: 2.625rem;
				object-fit: cover;
				border-radius: 50%;
			}
			.initials {
				color: #4060ea;
				font-size: 1.125rem;
				font-weight: 900;
				line-height: 132%;
				width: 2.5rem;
				height: 2.5rem;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background: #dae0fb;
				border: 2px solid #fff;
				flex-shrink: 0;
			}
			b {
				color: #2f2f2f;
				font-size: 0.9375rem;
				font-weight: 700;
				line-height: 132%;
				margin-bottom: 0.125rem;

				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;

				${({ $breakAtScreen }) => ($breakAtScreen ? maxQuery($breakAtScreen) : maxQuery(screenBreak))} {
					color: #fff;
				}
			}
			b + span {
				display: block;
				color: #8b8b8b;
				font-size: 0.6875rem;
				line-height: 132%;
				letter-spacing: -0.00344rem;

				${({ $breakAtScreen }) => ($breakAtScreen ? maxQuery($breakAtScreen) : maxQuery(screenBreak))} {
					color: #fff;
				}
			}
		}
		> svg {
			cursor: pointer;

			path {
				${({ $breakAtScreen }) => ($breakAtScreen ? maxQuery($breakAtScreen) : maxQuery(screenBreak))} {
					stroke: #fff;
					fill: #fff;
				}
			}
		}
	}
`;
