import { useMutation, useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';
import { BlogType, PatchBlogType, ServerGetResponse } from '../types';

export const useGetBlogs = ({ page = 1, page_size = 5, search = '', category = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<BlogType>> =>
			axiosPrivate
				.get(`${Appurls.blogs.get}?page=${page}&page_size=${page_size}&search=${search}&category=${category}`)
				.then((response) => response.data),
		queryKey: ['blogs', { page, page_size, search, category }],
	});
};
export const useGetBlog = ({ id }: { id: string | number }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<BlogType> => axiosPrivate.get(Appurls.blogs.single(id)).then((response) => response?.data),
		queryKey: ['blog', id],
	});
};

export const useAddBlogs = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((data: FormData) =>
		axiosPrivate.post(Appurls.blogs.create, data, { headers: { 'Content-Type': 'multipart/form-data' } })
	);
};

export const usePatchBlogs = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation(({ id, body }: { id: string | number; body: PatchBlogType | FormData }) =>
		axiosPrivate.patch(Appurls.blogs.single(id), body, { headers: { 'Content-Type': 'multipart/form-data' } })
	);
};

export const useDeleteBlog = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation(({ id }: { id: string | number }) => axiosPrivate.delete(Appurls.blogs.single(id)));
};
