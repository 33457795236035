export const queryStrings = {
	applicantId: 'a',
	businessId: 'b',
	concernsId: { intern: 'ci', startup: 'cs' },
	couponId: 'c',
	institutionId: 'i',
	message_new_room_id: 'm_id',
	match_id: 'm',
	notifications: 'n',
	profile_tab: 'p',
	should_edit_profile: 'pe',
	subscription_plan: 'sp',
	supervisor_token: 'token',
	institution_head_token: 'token',
	institution_supervisor_token: 'token',
	institution_intern_token: 'token',
};
