import { useQueryClient } from '@tanstack/react-query';
import { AddIcon, FileImage, RemoveIcon } from '../../../assets/svgs';
import { LEVEL_OF_EDUCATION } from '../../../constants';
import { useDeleteExperience, useNotify, useUpdateInternExperience, useUpdateInternProfile } from '../../../hooks';
import { Button, FileGroup, InputGroup, Loader, SelectGroup } from '../../../ui';
import { handleApiError } from '../../../utilities';
import { useState } from 'react';

const AboutCompany = ({ setStep, formData, handleChange, handleLogout }) => {
	const queryClient = useQueryClient();
	const notify = useNotify();
	const [isLoadingFor, setIsLoadingFor] = useState('');
	const [isDeletingFor, setIsDeletingFor] = useState('');
	const { mutate: updateProfile, isLoading } = useUpdateInternProfile();
	const { mutate: updateExperience, isLoading: isUpdatingExperience } = useUpdateInternExperience();
	const { mutate: deleteExperience, isLoading: isDeletingExperience } = useDeleteExperience();

	const handleSaveAndExit = () => {
		setIsLoadingFor('EXIT');

		handleDataSubmission({
			onSuccess: () => {
				handleLogout();
				setIsLoadingFor('');
			},
		});
	};
	const handleDataSubmission = ({ onSuccess }) => {
		const axiosFormData = new FormData();

		formData.level_of_education && axiosFormData.append('highest_edu_level', formData.level_of_education);
		formData.area_of_study && axiosFormData.append('area_of_study', formData.area_of_study);
		formData.institution && axiosFormData.append('recent_institution', formData.institution);
		formData.id_card.file && axiosFormData.append('id_card', formData.id_card.file);

		updateProfile(axiosFormData, {
			onSuccess,
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'profile_update' } }),
		});
		if (formData.experiences?.[0].employer_name !== '') {
			updateExperience({ experience: formData.experiences });
		}
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		setIsLoadingFor('CONTINUE');

		handleDataSubmission({
			onSuccess: () => {
				setStep(3);
				queryClient.invalidateQueries(['user']);
				queryClient.invalidateQueries(['user-id']);
				setIsLoadingFor('');
			},
		});
	};

	const handleAdd = (name, value, maxSize = 2) => {
		if (formData[name].length >= maxSize) {
			notify({
				message: `Only a maximum of ${maxSize} experiences can be added`,
				variant: 'minor',
				toastOptions: { toastId: 'input_max_row', position: 'bottom-center' },
			});
		} else {
			handleChange(null, name, [...formData[name], value]);
		}
	};
	const handleRemove = (name, index) => {
		if (formData[name].length !== 1) {
			handleChange(
				null,
				name,
				formData[name].filter((_el, elIndex) => elIndex !== index)
			);
		} else {
			notify({
				message: 'You must have at least one row',
				variant: 'minor',
				toastOptions: { toastId: 'input_min_row', position: 'bottom-center' },
			});
		}
	};
	const handleExperienceChange = (event, name, value, index) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		let newValue = [...formData.experiences]; /*.find((_el, elIndex) => elIndex !== index)[name] === value*/
		newValue.splice(index, 1, { ...formData.experiences[index], [name]: value });

		handleChange(null, 'experiences', newValue);
	};

	const handleExperienceDelete = (id) => {
		setIsDeletingFor(id);

		deleteExperience(
			{ id },
			{
				onSuccess: () => {
					queryClient.invalidateQueries(['user']);
					queryClient.invalidateQueries(['user-id']);
					setIsDeletingFor('');
				},
			}
		);
	};

	return (
		<form onSubmit={handleSubmit}>
			<h2>Education & Experience</h2>
			<p>Provide information to know your educational background & Your Exprience</p>

			<div>
				<SelectGroup
					label="Highest Level of Education"
					name="level_of_education"
					onChange={handleChange}
					placeholder="Select Level of Education"
					value={formData.level_of_education}
					options={LEVEL_OF_EDUCATION}
					required
				/>
				<InputGroup
					label="Area of Study"
					name="area_of_study"
					onChange={handleChange}
					placeholder="Enter Area of Study"
					value={formData.area_of_study}
					required
				/>
				<InputGroup
					label="Recent Institution Attended"
					name="institution"
					onChange={handleChange}
					placeholder="Enter Institution of Recent Education"
					value={formData.institution}
					required
				/>
				<FileGroup
					label="Institution ID Card/Certification Document"
					name="id_card"
					onChange={handleChange}
					helpTextTwo="PNG, JPEG, Doc, PDF. Max size of 1MB"
					accept="image/png, image/jpeg, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
					maxSize={1}
					moreInfo="Upload an image of your institution's ID card or a valid certificate you have of a training you took on a skill"
					value={formData.id_card}
					required
					openByDefault={new Date() < new Date('2025-01-01')}
					icon={FileImage}
				/>

				<div className="employment-con">
					<div>
						<label>Employer's Name</label>
						<label>Role/Position</label>
						<label>Employment Duration</label>
					</div>

					{formData.previous_experiences.length
						? formData.previous_experiences.map((experience, index) => (
								<div key={index}>
									<input type="text" placeholder="Enter Employers Name" disabled value={experience.employer_name} />
									<input type="text" placeholder="Enter Role or Position" disabled value={experience.role} />
									<input type="text" placeholder="Enter Employment Duration" disabled value={experience.duration} />
									{isDeletingFor === experience.id && isDeletingExperience ? (
										<span className="experience-loader">
											<Loader />
										</span>
									) : (
										<button className="remove-btn" type="button" onClick={() => handleExperienceDelete(experience.id)}>
											<RemoveIcon /> Remove
										</button>
									)}
								</div>
						  ))
						: null}
					{formData.experiences.map((experience, index) => (
						<div key={index}>
							<input
								type="text"
								placeholder="Enter Employers Name"
								name="employer_name"
								value={experience.employer_name}
								onChange={(event, name, value) => handleExperienceChange(event, name, value, index)}
							/>
							<input
								type="text"
								placeholder="Enter Role or Position"
								name="role"
								value={experience.role}
								onChange={(event, name, value) => handleExperienceChange(event, name, value, index)}
							/>
							<input
								type="text"
								placeholder="Enter Employment Duration"
								name="duration"
								value={experience.duration}
								onChange={(event, name, value) => handleExperienceChange(event, name, value, index)}
							/>
							<button
								className="remove-btn"
								type="button"
								disabled={formData.experiences.length === 1}
								onClick={() => handleRemove('experiences', index)}
							>
								<RemoveIcon /> Remove
							</button>
						</div>
					))}

					<Button
						variant="secondary"
						type="button"
						onClick={() =>
							handleAdd('experiences', {
								employer_name: '',
								role: '',
								duration: '',
							})
						}
					>
						<AddIcon /> Add another
					</Button>
				</div>
			</div>

			<div className="btn-group">
				<Button
					loading={(isLoading || isUpdatingExperience) && isLoadingFor === 'EXIT' ? isLoading || isUpdatingExperience : false}
					disabled={isLoading || isUpdatingExperience}
					type="button"
					variant="secondary"
					onClick={handleSaveAndExit}
				>
					Save and Exit
				</Button>
				<Button
					loading={(isLoading || isUpdatingExperience) && isLoadingFor === 'CONTINUE' ? isLoading || isUpdatingExperience : false}
					disabled={isLoading || isUpdatingExperience}
				>
					Save & Continue
				</Button>
			</div>
		</form>
	);
};

export default AboutCompany;
