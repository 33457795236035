import { useState } from 'react';
import { Container } from './styles';
import { Button, Loader } from '../../../../ui';
import { useGetBlog, useNotify, usePatchBlogs } from '../../../../hooks';
import { ButtonPlus, ProjectEmpty } from '../../../../assets/svgs';
import { EditBlogModal } from '../../modals';
import ShareArticle from './ShareArticle';
import { getDateTime } from '../../../../utilities';
import { useParams } from 'react-router-dom';

const Blogs = () => {
	const notify = useNotify();
	const [isEditingBlogsId, setIsEditingBlogsId] = useState<string | number>('');

	const { id } = useParams();

	const { data: blog, isLoading, isError, refetch } = useGetBlog({ id: id || '' });
	const { mutate: editBlog, isLoading: isEditing } = usePatchBlogs();

	const handlePublish = (id: string | number) => {
		editBlog(
			{ id, body: { is_published: true } },
			{
				onSuccess: () => {
					notify({
						message: `The post has been published`,
						status: 'success',
						toastOptions: { toastId: `publish_post_success-${id}` },
					});
					refetch();
				},
			}
		);
	};
	const handleSaveAsDraft = (id: string | number) => {
		editBlog(
			{ id, body: { is_published: false } },
			{
				onSuccess: () => {
					notify({
						message: `The post has been unpublished`,
						status: 'success',
						toastOptions: { toastId: `unpublish_post_success-${id}` },
					});
					refetch();
				},
			}
		);
	};

	return (
		<Container>
			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<center>
					<div>
						<ProjectEmpty />
						<p className="error-message">Unable to fetch this blog post at the moment</p>
					</div>
				</center>
			) : (
				<>
					<div className="header">
						<div>
							<h4>Blog Preview</h4>
						</div>

						<div className="btn-group">
							<Button variant="secondary" onClick={() => setIsEditingBlogsId(blog?.id || '')}>
								Edit
							</Button>
							<Button loading={isEditing} onClick={() => (blog?.is_published ? handleSaveAsDraft(blog?.id || '') : handlePublish(blog?.id || ''))}>
								<ButtonPlus /> {blog?.is_published ? 'Save as Draft' : 'Publish'}
							</Button>
						</div>
					</div>

					<div className="blog-container">
						<header>
							<h1>{blog?.title}</h1>
							<p className="date-time">Last Updated {getDateTime(blog?.updated_at, 'mm dd yyyy')}</p>
							<h6>BY {blog?.author}</h6>
						</header>

						<main>
							<div className="hero-img-con">
								<img src={blog?.thumbnail} alt={blog?.title} />
							</div>

							<div className="divider">
								<section className="content" dangerouslySetInnerHTML={{ __html: blog?.body }} />

								<section>
									{/* <MiniNewsletter /> */}
									<ShareArticle post={blog} />
								</section>
							</div>
						</main>
					</div>
				</>
			)}

			{isEditingBlogsId ? <EditBlogModal postId={isEditingBlogsId} handleClose={() => setIsEditingBlogsId('')} /> : null}
		</Container>
	);
};

export default Blogs;
