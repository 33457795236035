import { useRef, useState } from 'react';
import { Container } from './styles';
import { Loader, Pagination } from '../../../../ui';
import { useGetMatches } from '../../../../hooks';
import { EyeOpen, ProjectEmpty } from '../../../../assets/svgs';
import { useSearchParams } from 'react-router-dom';
import { getPreviousSearchParams } from '../../../../utilities';
import { queryStrings } from '../../../../constants';

const Matches = () => {
	const pageSize = useRef(10);
	const [page, setPage] = useState(1);
	const [searchParams, setSearchParams] = useSearchParams();

	const { data: matches, isLoading, isError } = useGetMatches({ page, page_size: pageSize.current, search: '' });

	const handleItemClick = (id: string) => {
		let previousParams = getPreviousSearchParams(searchParams);

		previousParams = { ...previousParams, [queryStrings.match_id]: id };
		setSearchParams(previousParams, { replace: true });
	};

	return (
		<Container>
			<div className="header">
				<h4>Matches</h4>
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<center>
					<div>
						<ProjectEmpty />
						<p className="error-message">Unable to fetch matches at the moment</p>
					</div>
				</center>
			) : !matches?.results || matches?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p className="error-message">No matches to display</p>
				</div>
			) : (
				<>
					{/* is_plan_specific */}
					{/* allowed_plans */}
					<div className="table-container">
						<table>
							<thead>
								<tr>
									<th>S/N</th>
									<th>Project</th>
									<th>Description</th>
									<th>Intern</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{matches?.results?.map((match, index) => (
									<tr key={match?.id}>
										<td>{index + 1}</td>
										<td>{match?.project_details.name}</td>
										<td>{match?.description}</td>
										<td>{match?.intern_details.name}</td>
										<td>
											<EyeOpen onClick={() => handleItemClick(match?.id?.toString())} />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{matches?.results?.map((match, index) => (
						<div key={match?.id} className="mobile-card">
							<div className="flexed">
								<div>
									<b>Description</b>
									<p>{match?.description}</p>
								</div>
								<div className="action-con">
									<EyeOpen onClick={() => handleItemClick(match?.id?.toString())} />
								</div>
							</div>
							<div className="flexed">
								<div>
									<b>Project</b>
									<p>{match?.project_details.name}</p>
								</div>
								<div>
									<b>Intern</b>
									<p>{match?.intern_details.name}</p>
								</div>
							</div>
						</div>
					))}

					<Pagination page={page} setPage={setPage} maxPages={Math.ceil(matches?.total / pageSize.current)} disabled={isLoading} />
				</>
			)}
		</Container>
	);
};

export default Matches;
