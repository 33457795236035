import styled from 'styled-components';
import { StyledButton } from '../../../ui/Button/styles';

export const Container = styled.div`
	height: 100vh;
	background-color: #f6f6f6;

	main {
		display: grid;
		place-items: center;
		text-align: center;
		height: calc(100vh - 10rem);

		h1 {
			font-size: 7rem;
			font-size: clamp(5.5rem, 4.6667rem + 2.6667vw, 7rem);
			font-family: cursive;
		}
		p {
			font-size: 1.2rem;
			font-size: clamp(1rem, 0.8889rem + 0.3556vw, 1.2rem);
			line-height: 1.5;
			margin-bottom: 1.5rem;
			margin-bottom: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
		}
		${StyledButton} {
			margin: auto;
			padding: 0.3rem 1rem;
		}
	}
`;
